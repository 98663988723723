import { Action, Reducer } from 'redux';

const getCataloghi = 'GET_CATALOGHI';
const setCataloghi = 'SET_CATALOGHI';
const clearCataloghi = 'CLEAR_CATALOGHI';
const initialState = {
    cataloghi: null
};


// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface Articolo{
    idArticolo: string,    
    descrizione: string,
    immagini: string[],
    immaginedefault: string
}


export interface Catalogo {
    id: string,
    descrizione: string,
    articoli: Articolo[],
}

export interface CatalogoState {
    cataloghi: Catalogo[] | null,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetCatalogoAction { type: typeof getCataloghi }
export interface SetCatalogoAction { type: typeof setCataloghi, cataloghi: Catalogo[] }
export interface ClearCatalogoAction { type: typeof clearCataloghi }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetCatalogoAction | GetCatalogoAction | ClearCatalogoAction; // | AnotherAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearCataloghi: () => ({ type: clearCataloghi } as ClearCatalogoAction | null),
    getCataloghi: () => ({ type: getCataloghi } as GetCatalogoAction),
    setCataloghi: (cataloghi: Catalogo[]) => ({ type: setCataloghi, cataloghi } as SetCatalogoAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CatalogoState> = (state: CatalogoState | undefined, incomingAction: Action): CatalogoState => {
    if (state === undefined) {
        return initialState;
    }

    //console.log("getcurrentuser failed", reason);

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case clearCataloghi:
            return {
                ...state, cataloghi:  initialState.cataloghi
            };

        case setCataloghi:
            return {
                ...state, cataloghi: { ...action.cataloghi }
            };
            
        default:
            return state;
    }



};


//setTimeout(() => this.fetchCurrentUser(), 1000);


//export const reducer = (state: any, action: any) => {
//    state = state || initialState;

//    if (action.type === hideMenuType) {
//        return { ...state, hidden: true };
//    };

//    return state;
//};

//export const mapStateToProps = (state: any) => {
//    return {
//        hidden: state.layout.hidden
//    };
//};

//export const mapDispatchToProps = (dispatch: any) => ({
//    hideMenu: () => dispatch({ type: hideMenuType }),
//});

