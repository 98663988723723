import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Tag, Typography, Row, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import ZuTable from '../Zerouno/ZuTable';

const { Title, Text, Paragraph } = Typography;

const msg_new = "Nuovo";

export default class ContenutiPromozionaliComponent extends React.Component<{}, any> {

    private readonly url = "api/ContenutiPromozionali";
    private readonly detailUrl = "/ContenutiPromozionaliDetail";
    private readonly title = "Contenuti promozionali";    

    render() {
        const columns: any[] = [
            {
                title: 'Titolo',
                dataIndex: 'titolo',
                ellipsis: true,
                //globalFilter: true,
                //sorter: true,
                width: '50%',
                render: (text: any, record: any) => <Text ellipsis><Link to={`${this.detailUrl}/${record.id}`}>{text}</Link></Text>

            },
            {
                title: 'Creato il',
                dataIndex: 'dataCreazione',
                dataType: 'date',
                width: '15%',
                //sorter: true,
                //defaultSortOrder: "descend",
                render: (text: any, record: any) => <>{text && moment(text).format("L")}</>,
            },
            {
                title: 'Ultima modifica',
                dataIndex: 'ultimaModifica',
                dataType: 'date',
                width: '15%',
                //sorter: true,
                //defaultSortOrder: "descend",
                render: (text: any, record: any) => <>{text && moment(text).format("L")}</>,
            },
            {
                title: 'Attivo',
                dataIndex: 'attivo',
                dataType: "bool",
                width: '10%',
                //sorter: true,
                // filters: [
                //     { text: "Si", value: 1 },
                //     { text: "No", value: 0 }
                // ],
                render: (text: any, record: any) => text && <CheckOutlined />,
            },
            
        ];
        return (
            <div>
                <Row justify="space-between" align="middle">
                    <Title level={2}><div>{this.title}</div></Title>
                    <Link to={this.detailUrl}><Button type="primary">{msg_new}</Button></Link>
                </Row>

                <ZuTable size="middle"
                    baseUrl={this.url}
                    columns={columns}
                    rowKey={(record: any) => { return record.id; }}
                    style={{
                        backgroundColor: 'white',
                    }}>
                </ZuTable>
            </div>
        );
    };
}