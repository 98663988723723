import { Action, Reducer } from 'redux';
import { StatoUtenteEnum } from '../utils/constants';

const getAccount = 'GET_ACCOUNT';
const setAccount = 'SET_ACCOUNT';
const clearAccount = 'CLEAR_ACCOUNT';
const initialState = {
    user: { isAuthenticated: false, name: "", username: "", roles: [], statoUtente: StatoUtenteEnum.Disattivo, isInitialState: true }
};


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface User {
    name: string,
    username: string,
    isAuthenticated: boolean,
    roles: string[],
    statoUtente: StatoUtenteEnum,
    isInitialState: boolean
}

export interface AccountState {
    user: User,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetAccountAction { type: typeof getAccount }
export interface SetAccountAction { type: typeof setAccount, user: User }
export interface ClearAccountAction { type: typeof clearAccount }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetAccountAction | GetAccountAction | ClearAccountAction; // | AnotherAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearAccount: () => ({ type: clearAccount } as ClearAccountAction),
    getAccount: () => ({ type: getAccount } as GetAccountAction),
    setAccount: (user: User) => ({ type: setAccount, user } as SetAccountAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AccountState> = (state: AccountState | undefined, incomingAction: Action): AccountState => {
    if (state === undefined) {
        return initialState;
    }

    //console.log("getcurrentuser failed", reason);

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case clearAccount:
            return {
                ...state, user: { ...initialState.user }
            };

        case setAccount:
            return {
                ...state, user: { ...action.user, isInitialState: false }
            };
            
        default:
            return state;
    }



};


//setTimeout(() => this.fetchCurrentUser(), 1000);


//export const reducer = (state: any, action: any) => {
//    state = state || initialState;

//    if (action.type === hideMenuType) {
//        return { ...state, hidden: true };
//    };

//    return state;
//};

//export const mapStateToProps = (state: any) => {
//    return {
//        hidden: state.layout.hidden
//    };
//};

//export const mapDispatchToProps = (dispatch: any) => ({
//    hideMenu: () => dispatch({ type: hideMenuType }),
//});

