const defaultOpts = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', //=>Unauthorized invece di RedirectToLogin
    },
}

export interface JsonDataFetchResult<T = any> {
    data?: T;
    response?: Response;
}
export interface JsonDataFetchErrorResult<T = any> {
    data?: T;
    response?: Response;
    error?: any;
}

/*
    versione : 22.10.20
    Changelog :
        22.10.20 nuova struttura + lettura data su error
*/

export async function jsonDataFetch<T = any>(url: string, opts?: any) {
    let res;
    let data;
    let err;

    try {

        const fetchOpts = { ...defaultOpts, ...opts }
        const response = await fetch(url, fetchOpts);

        res = await response;
        data = await res.json();
    }
    catch (e) {
        err = e;
    }

    if (res && res.ok) {
        return { data: data, response: res } as JsonDataFetchResult<T>;
    }
    else {
        throw { data: data, response: res, error: err } as JsonDataFetchErrorResult;
    }
}

/*
 *  ESEMPIO 
 * 
  
    this.setState({ loading: true });
    jsonDataFetch<{ qtaDisponibileLotto: number }>(url, fetchOpts)
        .then(x => {
            if (x.data) {
                this.setState({ disponibilita: x.data.qtaDisponibileLotto });
            }
        })
        .catch((x: JsonDataFetchErrorResult<{ message: string }>) => {
            if (x.data && x.data.message) {
                message.error(this.props.intl.formatMessage({ id: x.data.message }));)
            }
        })
        .finally(() => {
            this.setState({ loading: false });
        });
*/