import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore';
import * as CataloghiStore from '../store/cataloghiStore';
import Form, { FormInstance } from "antd/lib/form";
import { Card, Col, Row, Image, List, Dropdown, Button, message, notification, Menu, Modal, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { msg_requiredField, typeViewArticoli } from '../utils/constants';
import { Link } from 'react-router-dom';
import noImg from './noImg.png';
import ZuFormDetail from '../Zerouno/ZuFormDetail';

const { Meta } = Card;
const BASEPATHIMMAGINI = "StaticFiles";

class ListaArticoliComponent extends ZuFormDetail {
    formRefNewWishlist = React.createRef<FormInstance>();

    state = {
        cataloghi: [] as any,
        loadingAggiuntaCatalogo: false,
        creaCatalogo: false,
        idArticolo: null,
    }

    componentDidUpdate = (propsPrecedenti: any) => {
        if (propsPrecedenti.cataloghi != this.props.cataloghi) {
            let cataloghi = Object.values(this.props.cataloghi);
            this.setState({ cataloghi: cataloghi });
        }
    }

    async componentDidMount() {
        let cataloghi = [] as any;
        if (this.props.cataloghi != null) {
            cataloghi = Object.values(this.props.cataloghi);
        }

        this.setState({ cataloghi: cataloghi })
    }

    aggiungiArticoloAlCatalogo = async (idCatalogo: any, idArticolo: any) => {
        this.setState({ loadingAggiuntaCatalogo: true });
        let model = { idCatalogo: idCatalogo, idArticolo: idArticolo };

        const response = await fetch("api/Cataloghi/AddArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingAggiuntaCatalogo: false });
        }
    }

    aggiornaCataloghi = async () => {
        var res = await fetch("/api/Cataloghi/GetCataloghi");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCataloghi([]);
                this.setState({ creaCatalogo: false, loadingBtn: false, loadingAggiuntaCatalogo: false });
                return;
            } else {
                let cataloghi = json;
                // descrizioneLinea = json.descrizione;
                this.props.setCataloghi(cataloghi);
                notification.success({ message: 'Articolo aggiunto al catalogo' })
                this.setState({ creaCatalogo: false, loadingBtn: false, loadingAggiuntaCatalogo: false });
                return;
            }
        }
    }

    creaCatalogoAggiungiArticolo = async (values: any) => {
        this.setState({ loadingAggiuntaCatalogo: true });
        let model = { descrizione: values.nomeCatalogo, idArticolo: this.state.idArticolo };

        const response = await fetch("api/Cataloghi/CreateCatalogoAddArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingAggiuntaCatalogo: false });
        }
    }

    render() {
        const { loadingAggiuntaCatalogo, creaCatalogo } = this.state;
        const { items } = this.props;
        // const menuListe = (
        //     <Menu>
        //         {(this.state.cataloghi || []).map((x: any) =>
        //             <Menu.Item key={x.id} onClick={() => this.aggiungiArticoloAlCatalogo(x.id)}>{x.descrizione}</Menu.Item>
        //         )}
        //         <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
        //     </Menu>
        // );

        return (
            <>
                {creaCatalogo &&
                    <Modal
                        visible={creaCatalogo}
                        title="Crea nuovo catalogo"
                        onOk={() => this.formRefNewWishlist.current?.submit()}
                        onCancel={() => this.setState({ creaCatalogo: false })}
                        maskClosable={false}
                        forceRender={true} // necessario !!!
                        width={800}
                        okButtonProps={{ loading: loadingAggiuntaCatalogo }}
                    >
                        <Form ref={this.formRefNewWishlist} layout="vertical" onFinish={this.creaCatalogoAggiungiArticolo} >
                            <Row gutter={16}>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Nome catalogo" name="nomeCatalogo" rules={[{ required: true, message: msg_requiredField }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                }
                {items?.length > 0 ?
                    (this.props.typeView == typeViewArticoli.Lista ?
                        <List>
                            {(items || []).map((articolo: any) =>
                                <>
                                    <List.Item key={articolo.id}>
                                        <Col md={2}>
                                            <Image
                                                //key={i}
                                                preview={false}
                                                width={'100%'}
                                                src={articolo?.immaginedefault ? articolo?.immaginedefault : (articolo?.immagini?.length > 0 ? `${BASEPATHIMMAGINI}/${articolo?.immagini[0]}?t=${articolo.ultimamodifica}` : noImg)}
                                                // {...((articolo?.immagini?.length === 0 || !articolo?.immagini) && !articolo?.immaginedefault) && 
                                                //     { fallback: "data:/mg.png;"}}
                                                style={{ aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center center' }}
                                            />
                                        </Col>
                                        <Col md={16}>
                                            <List.Item.Meta
                                                title={<Link to={`/ArticoliDetail/${articolo.id}`}>{articolo.id}</Link>}
                                                description={articolo.descrizione}
                                            />
                                        </Col>
                                        <Col md={5} className="noprint">
                                            {this.state.cataloghi?.length > 0 ?
                                                // <Dropdown.Button disabled={loadingAggiuntaCatalogo} trigger={['click']} onClick={() => this.aggiungiArticoloAlCatalogo(this.state.cataloghi[0].id, articolo.id)}
                                                //     overlay={<Menu>
                                                //         {(this.state.cataloghi || []).map((x: any) =>
                                                //             <Menu.Item key={x.id} onClick={() => this.aggiungiArticoloAlCatalogo(x.id, articolo.id)}>{x.descrizione}</Menu.Item>
                                                //         )}
                                                //         <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                //     </Menu>}
                                                //     icon={<DownOutlined />}>
                                                //     {this.state.cataloghi[0].descrizione}
                                                // </Dropdown.Button>
                                                <Dropdown trigger={['click']} overlay={
                                                    <Menu>
                                                        {(this.state.cataloghi || []).map((x: any) =>
                                                            <Menu.Item key={x.id} onClick={() => this.aggiungiArticoloAlCatalogo(x.id, articolo.id)}>{x.descrizione}</Menu.Item>
                                                        )}
                                                        <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                    </Menu>}>
                                                    <Button block type='link'>Aggiungi al catalogo</Button>
                                                </Dropdown>
                                                :
                                                <Dropdown trigger={['click']} overlay={
                                                    <Menu>
                                                        <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                    </Menu>}>
                                                    <Button block type='link'>Aggiungi al catalogo</Button>
                                                </Dropdown>
                                            }
                                        </Col>
                                    </List.Item>
                                </>
                            )}
                        </List>
                        :
                        <Row>
                            {(items || []).map((articolo: any) =>
                                <Col md={6} key={articolo.id} style={{ padding: 8 }}>
                                    <Card
                                        size="small"
                                        style={{ width: '100%' }}
                                        hoverable
                                        cover={
                                            <Link to={`/ArticoliDetail/${articolo.id}`}>
                                                <Image                                                    
                                                    preview={false}
                                                    width={'100%'}
                                                    src={articolo?.immaginedefault ? articolo?.immaginedefault : (articolo?.immagini?.length > 0 ? `${BASEPATHIMMAGINI}/${articolo?.immagini[0]}?t=${articolo.ultimamodifica}` : noImg)}
                                                    // {...((articolo?.immagini?.length === 0 || !articolo?.immagini) && !articolo?.immaginedefault) && 
                                                    //     { fallback: "data:/mg.png;"}}
                                                    style={{ aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center center' }}
                                                />
                                            </Link>
                                        }
                                        actions={[
                                            <Row style={{ margin: '-12px 0px' }} >
                                                <Col span={24} className="noprint">
                                                    {this.state.cataloghi?.length > 0 ?
                                                        // <Dropdown.Button disabled={loadingAggiuntaCatalogo} trigger={['click']} onClick={() => this.aggiungiArticoloAlCatalogo(this.state.cataloghi[0].id, articolo.id)}
                                                        //     overlay={<Menu>
                                                        //         {(this.state.cataloghi || []).map((x: any) =>
                                                        //             <Menu.Item key={x.id} onClick={() => this.aggiungiArticoloAlCatalogo(x.id, articolo.id)}>{x.descrizione}</Menu.Item>
                                                        //         )}
                                                        //         <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                        //     </Menu>}
                                                        //     icon={<DownOutlined />}>
                                                        //     {this.state.cataloghi[0].descrizione}
                                                        // </Dropdown.Button>
                                                        <Dropdown trigger={['click']} overlay={
                                                            <Menu>
                                                                {(this.state.cataloghi || []).map((x: any) =>
                                                                    <Menu.Item key={x.id} onClick={() => this.aggiungiArticoloAlCatalogo(x.id, articolo.id)}>{x.descrizione}</Menu.Item>
                                                                )}
                                                                <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                            </Menu>}>
                                                            <Button block type='link'>Aggiungi al catalogo</Button>
                                                        </Dropdown>
                                                        :
                                                        <Dropdown trigger={['click']} overlay={
                                                            <Menu>
                                                                <Menu.Item key={"add"} onClick={() => this.setState({ creaCatalogo: true, idArticolo: articolo.id })} style={{ color: '#1890ff' }}>+ Crea un catalogo</Menu.Item>
                                                            </Menu>}>
                                                            <Button block type='link'>Aggiungi al catalogo</Button>
                                                        </Dropdown>                                                        
                                                    }
                                                </Col>
                                            </Row>
                                        ]}
                                    >
                                        <Link to={`/ArticoliDetail/${articolo.id}`}><Meta description={<><span style={{ color: 'black' }}>{articolo.id}</span><br />{articolo.descrizione}</>} /></Link>
                                    </Card>

                                </Col>
                            )}
                        </Row>
                    )
                    :
                    <List dataSource={[]} renderItem={item => <List.Item>{item}</List.Item>} />
                }
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(ListaArticoliComponent);
