import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore';
import * as CataloghiStore from '../store/cataloghiStore';
import { FormInstance } from "antd/lib/form";
import { Card, Col, Row, Form, Button, Input, PageHeader, Checkbox } from 'antd';
import { msg_requiredField } from '../utils/constants';
import ZuFormDetail from '../Zerouno/ZuFormDetail';
import { DownloadOutlined, UndoOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';


class ContenutiPromozionaliDetailComponent extends ZuFormDetail {
    readonly title = "Contenuto promozionale";
    formRef = React.createRef<FormInstance>();
    static defaultProps = {
        baseUrl: "api/ContenutiPromozionali",
    }

    state = {
        value: '',
        valueToHTML: '',
        loading: false,
        urlImageContenuto: "",
    }

    async componentDidMount() {
        if (!this.isNew)
            this.load(this.props.match.params.id).then((data: any) => {
                if (data !== undefined) {
                    this.formRef.current?.setFieldsValue(data);

                    this.setState({
                        valueToHTML: data.descrizione,
                        value: BraftEditor.createEditorState(data.descrizione),
                        urlImageContenuto: data.urlImmagineSfondo
                    })
                }
            });
    }

    handleChangeHtmlEditor = (value: any) => {
        this.setState({
            value: value,
            valueToHTML: value.toHTML()
        });
    };

    handleSubmit = async (values: any) => {
        values.descrizione = this.state.valueToHTML;
        this.submit(values).then((res) => {
            if (res.ok) window.history.back();
        });
    }

    checkTextNotification = () => {
        if (this.state.valueToHTML.length > 7 && this.state.valueToHTML !== '<p></p><p></p>') {
            return Promise.resolve();
        }
        return Promise.reject(msg_requiredField);
    };

    render() {
        return (
            <>
                <PageHeader
                    style={{ border: '1px solid rgb(235, 237, 240)', marginBottom: 4 }}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={this.title}
                    extra={[
                        <Button key="1" type="primary" disabled={this.state.loading} onClick={() => { this.formRef.current?.submit(); }}><DownloadOutlined /> Salva</Button>,
                        <Button key="2" type="default" disabled={this.state.loading} onClick={() => window.history.back()} ><UndoOutlined /> Annulla</Button>,
                    ]}>
                </PageHeader>
                <Card>
                    <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit} /*validateMessages={defaultValidateMessages}*/>
                        <Form.Item name="id" noStyle><Input hidden /></Form.Item>
                        <Form.Item name="dataCreazione" noStyle><Input hidden /></Form.Item>
                        <Form.Item name="ultimaModifica" noStyle><Input hidden /></Form.Item>

                        <Row gutter={16}>
                            <Col md={16} sm={16}>
                                <Form.Item name='titolo' label="Titolo" rules={[{ required: true, message: msg_requiredField }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8}>
                                <Form.Item label={<>&nbsp;</>} name="attivo" valuePropName="checked">
                                    <Checkbox>Attivo</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col md={24} sm={24}>
                                <Form.Item name='urlImmagineSfondo' label="Url immagine sfondo contenuto">
                                    <Input onChange={(e: any) => this.setState({ urlImageContenuto: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col md={24} sm={24}>
                                <Form.Item
                                    name='descrizione'
                                    label="Testo contenuto promozionale"
                                    required={true}
                                    rules={[{ validator: this.checkTextNotification }]}>
                                    <div style={{ backgroundColor: '#fff' }}>
                                        <BraftEditor
                                            placeholder={'Inserisci testo notifica'}
                                            value={this.state.value}
                                            onChange={this.handleChangeHtmlEditor}
                                            language='en'
                                            style={{ border: '1px solid #d9d9d9', background: "#f0f2f5" }}
                                            contentStyle={{
                                                padding: '10px 100px', maxHeight: 400, fontSize: 14, overflow: 'hidden',
                                                background: '#fff', backgroundImage: `url(${this.state.urlImageContenuto})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'
                                            }} //fino ad altezza 310 problema con barra latera scorrimento
                                            controls={['undo', 'redo', 'font-size', 'font-family', 'line-height', 'text-color', 'bold', 'italic', 'underline', 'strike-through', 'remove-styles', 'text-indent', 'text-align', 'list-ul', 'list-ol']}
                                            excludeControls={['media', 'fullscreen', 'letter-spacing', 'superscript', 'subscript', 'emoji', 'code', 'hr', 'link', 'blockquote', 'clear', 'headings']}
                                        />
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    };
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(ContenutiPromozionaliDetailComponent);