import { Button, Card, Checkbox, Col, Descriptions, Empty, Form, Input, message, notification, PageHeader, Row, Select, Tabs, Typography, } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { ruoli, StatoUtenteEnum } from "../utils/constants";
import ZuFormDetail from "../Zerouno/ZuFormDetail";
import { DownloadOutlined, UndoOutlined, SendOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { injectIntl } from 'react-intl';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';

const { Title, Text } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;


class UserDetailComponent extends ZuFormDetail {
    readonly title = this.props.intl.formatMessage({ id: "utente.utente" });
    formRef = React.createRef<FormInstance>();

    static defaultProps = {
        baseUrl: "api/Users",
    };

    state = {
        data: null,
        passwordChanged: false,
        confirmChanged: false,
        loading: false,
        statoUtente: null,
        datiAnagrafici: [] as any,
    };

    componentDidMount() {
        if (!this.isNew) {
            this.load(this.props.match.params.id).then((data: any) => {
                if (data != undefined) {
                    //data.password = "      "; serverside
                    //data.confirm = "      "; serverside
                    this.setState({ data: data, statoUtente: data.statoUtente, datiAnagrafici: data.datiAnagrafici });
                    this.formRef.current?.setFieldsValue(data);
                }
            });
        } else {
            this.setState({ statoUtente: StatoUtenteEnum.InAttivazione });
        }
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
    }

    handleSubmit = (values: any) => {
        let data = values;
        data.salvaEAttiva = false;
        //console.log("btnsalva", data);
        //debugger;
        // var oldPassword = this.state.data?.password;
        // if (oldPassword) {
        //     // forzo perche non posso verificare se la password è cambiata lato server
        //     data.passwordChanged = values.password !== oldPassword;
        // }
        if (!data.roles) data.roles = [];
        this.submit(data).then((res) => {
            if (res?.ok) window.history.back();
        });
    };

    //salvaAttiva = () => {
    //    this.formRef.current?.validateFields(["username", "email", "nomeCognome", "roles"])
    //        .then(x => {
    //            let data = this.formRef.current?.getFieldsValue();
    //            data.salvaEAttiva = true;
    //            //console.log("btnsalvaeattiva", data);
    //            if (!data.roles) data.roles = [];
    //            this.submit(data).then((res) => {
    //                if (res?.ok) window.history.back();
    //            });
    //        });
    //}

    inviaNuovaAttivazione = () => {
        this.formRef.current?.validateFields(["username", "email", "nomeCognome", "roles"])
            .then(async x => {
                let model = this.formRef.current?.getFieldsValue();
                const response = await fetch("api/Users/SendEmailActivation", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify({
                        ...model
                    })
                });
                const res = await response;
                let data: any = {};

                if (res.ok) {
                    notification.success({ message: "Mail inviata" });
                    window.history.back();
                }
                else {
                    message.error("Errore");
                }
            });
    }

    handlePasswordBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ passwordChanged: true });
    };
    handleConfirmBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ confirmChanged: true });
    };

    render() {
        const { data, passwordChanged, confirmChanged, statoUtente, datiAnagrafici } = this.state;

        let options = [] as any;
        if (this.props.user.roles.find((x: any) => x === ruoli.SuperAdmin)) {
            options = Object.keys(ruoli).map((k: any) => (
                <Option key={(ruoli as any)[k]} value={(ruoli as any)[k]}>
                    {k}
                </Option>
            ));
        } else {
            options = Object.keys(ruoli).map((k: any) => (
                k != ruoli.SuperAdmin &&
                <Option key={(ruoli as any)[k]} value={(ruoli as any)[k]}>
                    {k}
                </Option>
            ));
        }

        const msgPasswordNonCoincidono = this.props.intl.formatMessage({ id: "utente.msgPasswordNonCoincidono" });
        const msgPasswordNonValida = this.props.intl.formatMessage({ id: "utente.msgPasswordNonValida" });
        const msgEmailValida = this.props.intl.formatMessage({ id: "utente.msgEmailValida" });


        return (
            <>
                <PageHeader
                    style={{ border: "1px solid rgb(235, 237, 240)", marginBottom: 4, }}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={this.title}
                    extra={[
                        //statoUtente == StatoUtenteEnum.DaAttivare &&
                        //<Button key="3" type="primary" disabled={this.state.loading} onClick={() => { /*this.formRef.current?.submit();*/ this.salvaAttiva() }}>
                        //    <DownloadOutlined /> {this.props.intl.formatMessage({ id: "global.salvaEAttiva" })}
                        //    {/*  {this.props.intl.formatMessage({ id: "global.salva" })} */}
                        //</Button>,
                        (statoUtente == StatoUtenteEnum.InAttivazione && !this.isNew) &&
                        <Button key="4" type="primary" disabled={this.state.loading} onClick={() => { /*this.formRef.current?.submit();*/ this.inviaNuovaAttivazione() }} >
                            <SendOutlined /> {this.props.intl.formatMessage({ id: "global.inviaNuovaAttivazione" })}
                            {/*  {this.props.intl.formatMessage({ id: "global.salva" })} */}
                        </Button>,
                        <Button key="1" type="primary" disabled={this.state.loading} onClick={() => { this.formRef.current?.submit(); }}>
                            <DownloadOutlined /> {this.props.intl.formatMessage({ id: "global.salva" })}
                        </Button>,
                        <Button key="2" type="default" disabled={this.state.loading} onClick={() => window.history.back()} >
                            <UndoOutlined /> {this.props.intl.formatMessage({ id: "global.annulla" })}
                        </Button>,
                    ]}
                ></PageHeader>

                <Card>
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Dati account" key="1">
                            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                                <Form.Item noStyle name="id"><Input hidden /></Form.Item>
                                <Row gutter={16}>
                                    <Col md={10} sm={24}>
                                        <Form.Item label={this.props.intl.formatMessage({ id: "utente.username" })} name="username" rules={[{ required: true, },]} >
                                            <Input autoComplete="new-password" disabled={!this.isNew} />
                                        </Form.Item>
                                    </Col>

                                    <Col md={14} sm={24}>
                                        <Row gutter={16}>
                                            <Col md={24} flex="auto">
                                                <Form.Item label={this.props.intl.formatMessage({ id: "utente.email" })} name="email" rules={[{ required: true, }, { type: "email", message: msgEmailValida, },]} >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col md={24} flex="200px">
                                                {/*{(statoUtente == StatoUtenteEnum.DaAttivare || statoUtente == StatoUtenteEnum.InAttivazione) && !this.props.user.roles.find((x: any) => x === ruoli.SuperAdmin) ?*/}
                                                {/*    <Form.Item noStyle name="statoUtente"><Input hidden /></Form.Item>*/}
                                                {statoUtente == StatoUtenteEnum.InAttivazione ?
                                                    <Form.Item label={this.props.intl.formatMessage({ id: "utente.statoUtente" })} name="statoUtente" >
                                                        <>{this.props.intl.formatMessage({ id: "utente.statoUtente.inAttivazione" })}</>
                                                    </Form.Item>
                                                    :
                                                    <Form.Item label={this.props.intl.formatMessage({ id: "utente.statoUtente" })} name="statoUtente"/*valuePropName="checked"*/ >
                                                        {/* <Checkbox>Attivo</Checkbox> */}
                                                        <Select>
                                                            <Option value={StatoUtenteEnum.Attivo}>{this.props.intl.formatMessage({ id: "utente.statoUtente.attivo" })}</Option>
                                                            <Option value={StatoUtenteEnum.AttivoInAttesaDati}>{this.props.intl.formatMessage({ id: "utente.statoUtente.attivoInAttesaDati" })}</Option>
                                                            <Option value={StatoUtenteEnum.Disattivo}>{this.props.intl.formatMessage({ id: "utente.statoUtente.nonAttivo" })}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <Form.Item label={this.props.intl.formatMessage({ id: "utente.nomeCognome" })} name="nomeCognome" rules={[{ required: true, }, { max: 200, },]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item label={this.props.intl.formatMessage({ id: "utente.ruoli" })} name="roles" rules={[{ required: true, }]}>
                                            <Select mode="multiple" style={{ width: "100%" }} placeholder="">
                                                {options}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {this.props.user.roles.find((x: any) => x === ruoli.SuperAdmin) &&
                                    <Row gutter={16}>
                                        <Col md={12} sm={24}>
                                            <Form.Item label={this.props.intl.formatMessage({ id: "utente.password" })} hasFeedback name="password"
                                                rules={[{ required: true, }, { min: 8, message: msgPasswordNonValida, },]} >
                                                <Input.Password visibilityToggle={passwordChanged} onBlur={this.handlePasswordBlur} autoComplete="new-password" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <Form.Item label={this.props.intl.formatMessage({ id: "utente.confermaPassword" })} hasFeedback name="confirm" dependencies={["password"]}
                                                rules={[{ required: true, },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        var password = getFieldValue(
                                                            "password"
                                                        );
                                                        if (password !== value) {
                                                            return Promise.reject(
                                                                msgPasswordNonCoincidono
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                                ]}
                                            >
                                                <Input.Password visibilityToggle={confirmChanged} onBlur={this.handleConfirmBlur} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        </TabPane>
                        <TabPane tab="Dati anagrafici" key="2">
                            {datiAnagrafici != null ?
                                <Descriptions labelStyle={{ fontWeight: 'bold' }} column={4} bordered size="small">
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.ragionesociale" })} span={4}>{datiAnagrafici.ragioneSociale}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.indirizzo" })} span={4}>{datiAnagrafici.indirizzo}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.cap" })} >{datiAnagrafici.cap}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.citta" })} >{datiAnagrafici.citta}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.provincia" })} >{(datiAnagrafici.provincia)?.toUpperCase()}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.nazione" })} >{datiAnagrafici.nazione}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.partitaiva" })} >{datiAnagrafici.partitaIva}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.codicefiscale" })} span={3}>{datiAnagrafici.codiceFiscale}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.referente" })} >{datiAnagrafici.referente}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.email" })} span={2}>{datiAnagrafici.email}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.telefono" })} >{datiAnagrafici.telefono}</Descriptions.Item>
                                    <Descriptions.Item label={this.props.intl.formatMessage({ id: "utente.categorizzazione" })} span={4}>{datiAnagrafici.categorizzazione}</Descriptions.Item>
                                </Descriptions>
                                :
                                <Empty description="Non sono presenti dati anagrafici salvati" ></Empty>
                            }
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.account } },
    { ...AccountStore.actionCreators }
)(injectIntl(UserDetailComponent));
