import React, { Component } from 'react'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select';
import { operators } from './helpers';
const { Option } = Select;

export default class Operators<ValueType extends SelectValue = SelectValue> extends React.Component<SelectProps<ValueType> & {type: "string" | "number" }> {

    render() {
        const op = Object.keys(operators)
            .map((x: any) => (operators as any)[x])
            .filter((x: any) => x[`${this.props.type}Type`]);
            
        return (
            <Select 
                optionLabelProp="label" 
                dropdownMatchSelectWidth={false} 
                style={{ width: 45 }} 
                showArrow={false} 
                {...this.props} 
            >
                {op.map((x: any) => 
                    <Option key={x.key} value={x.key} title={x.label} label={x.symbol}><span style={{width: 70}}>{x.symbol}</span> {x.label}</Option>
                )}
            </Select>
        )
    }
}


