import { Action, Reducer } from 'redux';
import { ShorthandPropertyAssignment } from 'typescript';

const getCarrello = 'GET_CARRELLO';
const setCarrello = 'SET_CARRELLO';
const clearCarrello = 'CLEAR_CARRELLO';
const initialState = {
    carrello: null
};


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ArticoloQuantita {
    id: ShorthandPropertyAssignment,
    idArticolo: string,
    quantita: number,
    idCatStatistica: string,
    descrizione: string,
    immagini: string[],
    immaginedefault: string
}

export interface CarrelloState {
    carrello: ArticoloQuantita[] | null,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetCarrelloAction { type: typeof getCarrello }
export interface SetCarrelloAction { type: typeof setCarrello, carrello: ArticoloQuantita[] }
export interface ClearCarrelloAction { type: typeof clearCarrello }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetCarrelloAction | GetCarrelloAction | ClearCarrelloAction; // | AnotherAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearCarrello: () => ({ type: clearCarrello } as ClearCarrelloAction | null),
    getCarrello: () => ({ type: getCarrello } as GetCarrelloAction),
    setCarrello: (carrello: ArticoloQuantita[]) => ({ type: setCarrello, carrello } as SetCarrelloAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CarrelloState> = (state: CarrelloState | undefined, incomingAction: Action): CarrelloState => {
    if (state === undefined) {
        return initialState;
    }

    //console.log("getcurrentuser failed", reason);

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case clearCarrello:
            return {
                ...state, carrello:  initialState.carrello
            };

        case setCarrello:
            return {
                ...state, carrello: { ...action.carrello }
            };
            
        default:
            return state;
    }



};


//setTimeout(() => this.fetchCurrentUser(), 1000);


//export const reducer = (state: any, action: any) => {
//    state = state || initialState;

//    if (action.type === hideMenuType) {
//        return { ...state, hidden: true };
//    };

//    return state;
//};

//export const mapStateToProps = (state: any) => {
//    return {
//        hidden: state.layout.hidden
//    };
//};

//export const mapDispatchToProps = (dispatch: any) => ({
//    hideMenu: () => dispatch({ type: hideMenuType }),
//});

