import * as React from 'react';
import {  Tree, TreeSelect, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { jsonDataFetch, JsonDataFetchErrorResult } from '../../Zerouno/jsonDataFetch';

const {Text} = Typography;

interface IProps {
  indexName: string;
  filter?: string;
  bordered?: boolean;
  ids: any[],
  setCustomData: (customData: any) => void,
  customData?: any,
}

export default function CategorieSelector(props: IProps) {

    const [items, setItems] = useState([] as any[]);
    const [itemsTree, setItemsTree] = useState([] as any[]);

    useEffect(() => {
      const fetchOpts = {
          body: JSON.stringify({ take: 200, skip: 0, orderby: "", /*filter: props.filter,*/ customData: { ids: props.ids?.map(x => x.key) } })
      };

      jsonDataFetch(`api/Query/categorie`, fetchOpts)
        .then(x => {
          if (x.data) {
            let rows = x.data?.rows || [];
            let items = rows.map((i: any) => (
              {
                key: 'c.' + i.id,
                value: 'c.' + i.id,
                title: i.descrizione,
                children: i.sottocategorie.map((i: any) => i.id)
              }));

            let itemsTree = rows.map((i: any) => (
              {
                key: 'c.' + i.id,
                value: 'c.' + i.id,
                title: i.descrizione
              }));
            setItems(items);
            setItemsTree(itemsTree);
          }
        })
        .catch((x: JsonDataFetchErrorResult<{ message: string }>) => {
          if (x.data && x.data.message) {
            //TODO: Come gestiamo l'intl in questo progetto?
            //message.error(this.props.intl.formatMessage({ id: x.data.message }));)
          }
        })
        .finally(() => {

        });
        
    }, [props.ids, props.filter]);

    function setCheckedKeys (checkedKeys: any) {    
      let { customData, indexName } = props;
      let zero = [] as any;
      checkedKeys.forEach((element: any) => {
        let categoria = (items.find((i:any)=> i.key == element));
        (categoria.children).forEach((e: any)=>zero.push(e));
      });

      // let one = zero.filter((item: any) => !(item.startsWith('c.')));
      // let two = one.map((item: any)=> parseInt(item.replace("s.","")));

      let newData = {...customData};
      newData[indexName] = zero;

      props.setCustomData(newData);
    }

    return (
      <>
            <Tree style={{ maxHeight: 104, overflow: "auto", marginLeft: -16 }}
                treeData={itemsTree}
                checkable
                onCheck={(checkedKeys: any)=>setCheckedKeys(checkedKeys)}
          />
      </>
    );
}

