import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
const { Text } = Typography

export const msg_search = "Cerca";
export const msg_confirm = "Ok";
export const msg_reset = "Reset";
export const msg_addItem = "Aggiunge una condizione di ricerca";

export type operatorType = "=" | ">" | "<" | ">=" | "<=" | "!=" | "*" | "^" | "><" | "D" | "W" | "WW" | "M" | "YTD";
export type dataType = "number" | "string" | "date" | "datetime";

export interface FilterValue {
    operator: operatorType;
    value: string;
    value2?: string;
}


// const startsWithFragment = <span 
// style={{
//     paddingLeft: "3px",
//     border: "1px solid",
//     borderRight: "none",
//     borderTopLeftRadius: "6px",
//     borderBottomLeftRadius: "6px",
//     backgroundColor: "#e8e8e8",
//   }}
//   >abc</span>

//   const containsFragment = <span 
//   style={{
//       paddingLeft: "2px",
//       paddingRight: "2px",
//       border: "1px solid",
//       borderRadius: "6px",
//       backgroundColor: "#e8e8e8",
//     }}
//     >xyz</Icon>

const containsFragment = <span style={{fontSize: "9pt"}}>a<span style={{ backgroundColor: "#D0D0D0" }}>b</span>c</span>
const startsWithFragment = <span style={{fontSize: "9pt"}}><span style={{ backgroundColor: "#D0D0D0" }}>ab</span>c</span>

export const operators = {
    // Stringhe
    // startsWith: { label: "Inizia con", key: "^" as operatorType, symbol: <><span style={{color: "orange", fontStyle: "italic"}}>sr</span>t</>, stringType: true, numberType: false, dateType: false },
    contains: { label: "Contiene", key: "*" as operatorType, symbol: containsFragment, stringType: true, numberType: false, dateType: false },
    startsWith: { label: "Inizia con", key: "^" as operatorType, symbol: startsWithFragment, stringType: true, numberType: false, dateType: false },

    // Generali
    eq: { label: "Uguale a", key: "=" as operatorType, symbol: "=", stringType: true, numberType: true, dateType: true },
    gt: { label: "Maggiore di", key: ">" as operatorType, symbol: <>&gt;</>, stringType: true, numberType: true, dateType: true },
    lt: { label: "Minore di", key: "<" as operatorType, symbol: <>&lt;</>, stringType: true, numberType: true, dateType: true },
    ge: { label: "Maggiore o uguale a", key: ">=" as operatorType, symbol: <>&ge;</>, stringType: true, numberType: true, dateType: true },
    le: { label: "Minore o uguale a", key: "<=" as operatorType, symbol: <>&le;</>, stringType: true, numberType: true, dateType: true },
    neq: { label: "Diverso da", key: "!=" as operatorType, symbol: <>&ne;</>, stringType: true, numberType: true, dateType: true },
    range: { label: "Compreso tra", key: "><" as operatorType, symbol: <>&gt;&lt;</>, stringType: false, numberType: false, dateType: false }, // TODO da attivare
    
    // Date 
    today: { label: "Oggi", key: "D" as operatorType, symbol: "D", stringType: false, numberType: false, dateType: true },
    lastWeek: { label: "Ultima settimana", key: "W" as operatorType, symbol: "W", stringType: false, numberType: false, dateType: true },
    monthToDate: { label: "Da inizio mese", key: "MTD" as operatorType, symbol: "M", stringType: false, numberType: false, dateType: true },
    month: { label: "Nel mese di", key: "M" as operatorType, symbol: "M", stringType: false, numberType: false, dateType: true },
    yearToDate: { label: "Da inizio anno", key: "YTD" as operatorType, symbol: "YTD", stringType: false, numberType: false, dateType: true },
};

export function getFilterValue(key: FilterValue | React.ReactText | string | number | undefined, defaultOperator: operatorType): FilterValue {
    if (key == null) return { operator: defaultOperator, value: "" };

    if (typeof(key) != 'object') {
        let x = key.toString();
        if (x.startsWith("{")) {
            key = JSON.parse(x) as FilterValue;
        } else {
            key = { operator: defaultOperator, value: x };
        }
    }
    return key;
}


export function composeFilterString(column: any, filterValue: any) {
    if(!column || column.onFilter) return null; // client-side filtering
    
    let f = getFilterValue(filterValue, "=");

    const dataType = column.dataType?.toLowerCase()

    let formattedValue = "";
    let value = null;
    let formattedValue2 = "";
    let value2 = null;

    let rangeEndOp = "<=";

    switch (dataType) {
        case "number":
            value = Number.parseFloat(f.value);
            if (!Number.isFinite(value)) return null;
            formattedValue = `${value}`;
            break;

        case "bool":
        case "boolean":
            if (typeof (f.value) === 'boolean') {
                formattedValue = (f.value as boolean).toString();
                break;
            }
            value = Number.parseFloat(f.value);
            if (!Number.isFinite(value)) return null;
            formattedValue = value > 0 ? 'true' : 'false';
            break;

        case "date":
        case "datetime":
            rangeEndOp = "<";

            value = moment(f.value || "*INVALID*", "L"); // Attenzione un parametro undefined in moment diventa la data corrente
            if (value.isValid())
                formattedValue = `"${value.format('YYYY-MM-DD')}"`;

            value2 = moment(f.value2 || "*INVALID*", "L"); // Attenzione un parametro undefined in moment diventa la data corrente
            if (value2.isValid())
                formattedValue2 = `"${value2.add(1, "day").format('YYYY-MM-DD')}"`;

            break;

        default:
            f = getFilterValue(filterValue, "*");
            formattedValue =`"${f.value}"`;
            value = f.value;
            break;
    }

    switch (f.operator) {
        case operators.eq.key:
        case operators.gt.key:
        case operators.lt.key:
        case operators.ge.key:
        case operators.le.key:
        case operators.neq.key:
            return `${column.dataIndex} ${f.operator} ${formattedValue}`;

        case operators.range.key:
            let s = "";
            if (formattedValue)
                s = `${column.dataIndex} >= ${formattedValue}`;
            if (formattedValue2)
                s += `${s && " AND " } ${column.dataIndex} ${rangeEndOp} ${formattedValue2}`;
            return s;

        case operators.startsWith.key:
            return `${column.dataIndex}.StartsWith(${formattedValue})`;

        case operators.contains.key:
            return `${column.dataIndex}.Contains(${formattedValue})`;
        
        case operators.today.key:
            return `${column.dataIndex} >= "${moment(value).format('YYYY-MM-DD')}" \
                and ${column.dataIndex} < "${moment(value).add(1, "day").format('YYYY-MM-DD')}"`;

        case operators.month.key:
            return `${column.dataIndex} >= "${moment(value).format('YYYY-MM-01')}" \
                and ${column.dataIndex} < "${moment(value).endOf("month").add(1, "day").format('YYYY-MM-DD')}"`;

        case operators.lastWeek.key:
            return `${column.dataIndex} >= "${moment(value).subtract(1, "week").add(1, "day").format('YYYY-MM-DD')}" \
                and ${column.dataIndex} < "${moment(value).add(1, "day").format('YYYY-MM-DD')}"`;

        case operators.yearToDate.key:
            return `${column.dataIndex} >= "${moment().format('YYYY-01-01')}" \
                and ${column.dataIndex} < "${moment(value).add(1, "day").format('YYYY-MM-DD')}"`;
    }


}
