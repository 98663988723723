import * as React from "react";
import { Button, Card, Result } from "antd";
import { FormInstance } from "antd/lib/form";
import { injectIntl } from 'react-intl';
import ZuFormDetail from "../Zerouno/ZuFormDetail";

class UserCompletaDatiUtenteResultComponent extends ZuFormDetail {
    readonly title = this.props.intl.formatMessage({ id: "utente.utente" });
    formRef = React.createRef<FormInstance>();
    recaptchaRef = React.createRef<any>();

    static defaultProps = {

    };

    state = {

    };

    componentDidMount() {

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
    }

    handleOkResult = () => {
        fetch('api/account/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(
                (succeded) => {
                    if (succeded) {
                        this.props.clearAccount();
                        this.props.clearCarrello();
                        this.props.clearCataloghi();
                        this.props.history.push("/");
                        return;
                    }

                    console.info("logout failed");
                },
                (error) => {
                    console.error(error);
                }
            )
    }

    handleGoLogin = () => {
        this.props.history.push("/");
    }

    render() {

        return (
            <>
                <Card>
                    <Result
                        status="success"
                        title="Profilo utente completato con successo"
                        subTitle="Dati anagrafici inseriti correttamente, si prega di rieffettuare il login"
                        extra={
                            [
                                <Button type="primary" key="console" onClick={this.handleGoLogin}>
                                    Vai alla login
                                </Button>
                            ]
                        }
                    />
                </Card>
            </>
        );
    }
}

export default injectIntl((UserCompletaDatiUtenteResultComponent))
