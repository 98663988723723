import * as React from 'react';
import ZuSelect from '../../Zerouno/ZuSelect';
import { Typography } from "antd";
import { LabeledValue, SelectValue } from 'antd/lib/select';
const { Text } = Typography;


//TODO: Temporaneo per progetto Faro

export default class ZuSelectElasticFaro extends ZuSelect {

    getSelectValue = (value: string | number | LabeledValue) => {
        return Object.keys(value).find(x => x == "value") ? (value as LabeledValue).value : value;
    }

    filterById = async (id: SelectValue | null) => {
        
        var ids;

        if (this.props.mode == "multiple") {
            if (!Array.isArray(id) || id.length == 0) return;
            
            ids = id.map(x => this.getSelectValue(x));
            take = id.length;
        }
        else
        {
            var value = this.getSelectValue(id as string | number | LabeledValue);
            if (value == null) return;
            
            var take = 1;
            ids = [value];
        }

        let filter = (this.props.filter && !this.props.excludeFilterOnIdLoookup)
            ? `(${this.props.filter})` : null;

        let params = {
            take: take,
            skip: 0,
            orderby: "",
            filter: filter,
            customData: {ids: ids },
            select: `new (${this.props.textColumn}, ${this.props.valueColumn})`,
        }

        this.fetch(params);
    }

}


