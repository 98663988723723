import './Home.less';
import * as React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Carousel, message } from 'antd';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore';
import * as CataloghiStore from '../store/cataloghiStore';
import BraftEditor from 'braft-editor';


class HomePrivateComponent extends  React.Component<any, any> {
  state = {
    contenuti: undefined as any | undefined,
  };

  componentDidMount = () => {
    fetch("api/ContenutiPromozionali/GetContenutiCarousel", {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
      .then((resp) =>
        resp.ok
          ? resp.json()
          : () => { message.error("Errore caricamento contenuti"); }
      )
      .then((data) => {                
        if (data.length == 0){
          this.setState({ contenuti: null });
        }
        this.setState({ contenuti: data });
      });
  }

  render() {
    const { contenuti } = this.state;
    let carousel = <></>
    if (contenuti?.length > 0) {
      carousel = contenuti.map((c: any) => {
        return (
          <div key={c.id} >
            {/* <div key={c.id} style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff', 
                    backgroundImage: `url(${c.urlImmagineSfondo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
                        <div className="content" dangerouslySetInnerHTML={{ __html: c.descrizione }} style={{padding: '0px 100px'}}></div>
                </div> */}
            <BraftEditor
              readOnly={true}
              placeholder={'Inserisci testo notifica'}
              value={BraftEditor.createEditorState(c.descrizione)}
              language='en'
              contentStyle={{
                padding: '10px 100px', maxHeight: 400, fontSize: 14, overflow: 'hidden',
                background: '#fff', backgroundImage: `url(${c.urlImmagineSfondo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'
              }} //fino ad altezza 310 problema con barra latera scorrimento
              controls={[]}
            />
          </div >
        )
      });
    } else {
      //carousel = <div><p style={{ height: '160px', color: '#000', lineHeight: '160px', textAlign: 'center', background: '#fff', }}>N</p></div>
    }

    if (!this.state.contenuti){
      return null;
    }
    if (this.state.contenuti.length > 0){
      return (
        <Carousel autoplay>
          {carousel}
        </Carousel>
      );
    }
    return(
      <Redirect to="/Catalogo"/>
    )
  };

}

export default connect(
  (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
  { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(HomePrivateComponent);