import { Button, Card, Col, Divider, Form, Input, message, notification, Result, Row, Select, Space, } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import ZuFormDetail from "../Zerouno/ZuFormDetail";
import { FormInstance } from "antd/lib/form";
import { injectIntl } from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";
import { CategorizzazioniUser, msg_operationError, msg_standardValidationError, msg_unexpectedError } from "../utils/constants";

const { Option } = Select;
const { TextArea } = Input;
class UserRegistrazioneComponent extends ZuFormDetail {
    readonly title = this.props.intl.formatMessage({ id: "utente.utente" });
    formRef = React.createRef<FormInstance>();
    recaptchaRef = React.createRef<any>();

    static defaultProps = {

    };

    state = {
        passwordChanged: false,
        confirmChanged: false,
        captcha: false,
        loading: false,
        resultSuccess: false,
    };

    componentDidMount() {

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });
        let data = {
            ...values,
            RecaptchaToken: this.recaptchaRef?.current?.getValue()
        };
        console.log(data);

        const response = await fetch("api/Account/RegistrationUser", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...data
            })
        });
        const res = await response;

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            //notification.success({ message: "Abbiamo ricevuto correttamente la sua richiesta di registrazione", description: "Un nostro operatore prenderà in gestione il prima possibile la sua richiesta" })
            //window.location.href = "/login";
            //window.history.back();
            this.setState({ loading: false, resultSuccess: true });
        }
        else {
            //reset captcha
            this.recaptchaRef.current.reset();
            let json = await response?.json();
            if (typeof json.message === 'string' || json.message instanceof String) {
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message}</>,
                    "duration": 60
                });
            }
            else {
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message.map((x: any) => <p>{x.description}</p>)}</>,
                    "duration": 60
                });
            }

            this.setState({ loading: false });
        }
    };

    handlePasswordBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ passwordChanged: true });
    };
    handleConfirmBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ confirmChanged: true });
    };
    onReCaptchaChange = (value: any) => {
        this.setState({
            captcha: value,
        });
    };

    render() {
        const { passwordChanged, confirmChanged, resultSuccess } = this.state;

        const msgPasswordNonCoincidono = this.props.intl.formatMessage({ id: "utente.msgPasswordNonCoincidono" });
        const msgPasswordNonValida = this.props.intl.formatMessage({ id: "utente.msgPasswordNonValida" });
        const msgEmailValida = this.props.intl.formatMessage({ id: "utente.msgEmailValida" });
        return (
            <>
                <Card>
                    {resultSuccess ?
                        <Result
                            status="success"
                            title="Registrazione avvenuta con successo"
                            subTitle="Abbiamo inviato una mail all'indirizzo indicato per completare l'attivazione"
                        // extra={[
                        //     <Button type="primary" key="console">
                        //         Go Console
                        //     </Button>,
                        //     <Button key="buy">Buy Again</Button>,
                        // ]}
                        />
                        :
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.handleSubmit}
                        >
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Divider orientation="left" orientationMargin="0">{this.props.intl.formatMessage({ id: "utente.datiaccount" })}</Divider>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item label={this.props.intl.formatMessage({ id: "utente.username" })} name="username" rules={[{ required: true, },]}>
                                        <Input autoComplete="new-password" disabled={!this.isNew} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item label={this.props.intl.formatMessage({ id: "utente.email" })} name="email" rules={[{ required: true }, { type: "email", message: msgEmailValida, },]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item label={this.props.intl.formatMessage({ id: "utente.nomeCognome" })} name="nomeCognome" rules={[{ required: true, }, { max: 200, }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Divider orientation="left" orientationMargin="0">{this.props.intl.formatMessage({ id: "utente.dataianagraficiazienda" })}</Divider>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.ragionesociale" })} name="ragioneSociale" rules={[{ required: true, }, { max: 100, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.referente" })} name="referente" rules={[{ required: true, }, { max: 150, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.telefono" })} name="telefono" rules={[{ required: true, }, { max: 50, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.indirizzo" })} name="indirizzo" rules={[{ required: true, }, { max: 100, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.citta" })} name="citta" rules={[{ required: true, }, { max: 100, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 4 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.cap" })} name="cap" rules={[{ required: true, }, { max: 10, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*    <Col md={{ span: 4 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.provincia" })} name="provincia" rules={[{ required: true, }, { max: 50, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.nazione" })} name="nazione" rules={[{ required: true, }, { max: 100, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.partitaiva" })} name="partitaIva" rules={[{ required: true, }, { max: 16, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.codicefiscale" })} name="codiceFiscale" rules={[{ required: true, }, { max: 16, }]}>*/}
                            {/*            <Input />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.categorizzazione" })} name="categorizzazione" rules={[{ required: true, }]}>*/}
                            {/*            <Select>*/}
                            {/*                <Option value={CategorizzazioniUser.ProduttoreScarpe}>{CategorizzazioniUser.ProduttoreScarpe}</Option>*/}
                            {/*                <Option value={CategorizzazioniUser.ProduttoreBorse}>{CategorizzazioniUser.ProduttoreBorse}</Option>*/}
                            {/*                <Option value={CategorizzazioniUser.ProduttoreCinture}>{CategorizzazioniUser.ProduttoreCinture}</Option>*/}
                            {/*                <Option value={CategorizzazioniUser.Altro}>{CategorizzazioniUser.Altro}</Option>*/}
                            {/*            </Select>*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row gutter={16}>*/}
                            {/*    <Col md={{ offset: 8, span: 8 }} sm={24}>*/}
                            {/*        <Form.Item label={this.props.intl.formatMessage({ id: "utente.annotazioni" })} name="annotazioni" rules={[{ max: 500, }]}>*/}
                            {/*            <TextArea rows={2} />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24} style={{ paddingBottom: 16 }}>
                                    <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        sitekey="6Ldb4eUeAAAAALyj4GvaEuu4xjF2Xj-6dXF3ak7W"
                                        onChange={this.onReCaptchaChange}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit" loading={this.state.loading}>{this.props.intl.formatMessage({ id: "global.conferma" })}</Button>
                                            <Button onClick={() => window.history.back()}>{this.props.intl.formatMessage({ id: "global.annulla" })}</Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Card>
            </>
        );
    }
}

export default injectIntl(
    connect()(UserRegistrazioneComponent)
)
