
export const msg_requiredField = "E' obbligatorio inserire un valore";
export const msg_maxLength = "Superata la lunghezza massima consentita";
export const msg_unableToLoadData = "Impossibile caricare i dati";
export const msg_unexpectedError = "Si e' verificato un errore imprevisto: contattare l'amministratore";
export const msg_operationOk = "Operazione completata";
export const msg_operationError = "Operazione non riuscita";
export const msg_standardValidationError = "One or more validation errors occurred.";

export const ruoli = {
    Amministratore: "Amministratore",
    Cliente: "Cliente",
    SuperAdmin: "SuperAdmin",
};

export const policies = (user: any) => {
    var r = (user && user.roles) || [];
    return {
        Default: r.some((x: any) =>
            x === ruoli.Amministratore ||
            x === ruoli.Cliente,
        )
    };
}

export const typeViewArticoli = {
    Lista: "Lista",
    Griglia: "Griglia",
};

export enum StatoUtenteEnum {
    Attivo = 1,
    Disattivo = 2,
    InAttivazione = 3,
    AttivoInAttesaDati = 4
}

export enum CategorizzazioniUser {
    ProduttoreScarpe = "Produttore scarpe",
    ProduttoreBorse = "Produttore borse",
    ProduttoreCinture = "Produttore cinture",
    Altro = "Altro"
}