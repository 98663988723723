import { Button, Row, Space, Tag, Tooltip, Typography } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ruoli, StatoUtenteEnum } from "../utils/constants";
import ZuTable from "../Zerouno/ZuTable";
import { injectIntl } from 'react-intl';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;


class UsersComponent extends React.Component<any> {
    private readonly url = "api/Users";
    private readonly detailUrl = "/UserDetail";
    private readonly title = this.props.intl.formatMessage({ id: "utente.utenti" });

    render() {
        const msg_new = this.props.intl.formatMessage({ id: "global.nuovo" });
        const columns: any = [
            // {
            //     title: 'Id',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: this.props.intl.formatMessage({ id: "utente.username" }),
                dataIndex: "username",
                filter: true,
                sorter: true,
                globalFilter: true,
                defaultSortOrder: "ascend",
                render: (value: any, record: any) => (
                    <Link to={`${this.detailUrl}/${record.id}`}>{value}</Link>
                ),
            },
            {
                title: this.props.intl.formatMessage({ id: "utente.nomeCognome" }),
                dataIndex: "nomeCognome",
                filter: true,
                sorter: true,
                globalFilter: true,
            }, {
                title: this.props.intl.formatMessage({ id: "utente.email" }),
                dataIndex: "email",
                key: "email",
                globalFilter: true,
                sorter: true,
                filter: true,
            },
            {
                title: this.props.intl.formatMessage({ id: "utente.ruoli" }),
                filters: Object.keys(ruoli).map((r: any) => {
                    return {
                        text: r,
                        value: (ruoli as any)[r],
                    };
                }),
                onFilter: (value: any, record: any) => {
                    return record.roles.includes(value);
                },
                render: (value: any, record: any) => (
                    <span>
                        {record.roles?.map((r: string) => {
                            let color = "";

                            switch (r) {
                                case ruoli.Amministratore:
                                    color = "red";
                                    break;

                                case ruoli.Cliente:
                                    color = "green";
                                    break;
                            }

                            return (
                                <Tag color={color} key={r}>
                                    {r.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </span>
                ),
            },
            {
                title: <Space>{this.props.intl.formatMessage({ id: "utente.statoUtente" })}
                    <Tooltip
                        title={
                            <Space direction="vertical">
                                <Row><Text strong style={{ color: "#FFFFFF" }}>{this.props.intl.formatMessage({ id: "utente.statoUtente.inAttivazione" })}</Text>L'utente si è registrato, ma non ha ancora cliccato il link di verifica della mail</Row>
                                <Row><Text strong style={{ color: "#FFFFFF" }}>{this.props.intl.formatMessage({ id: "utente.statoUtente.attivoInAttesaDati" })}</Text>L'utente ha completato l'attivazione, ma non ha ancora compilato i dati anagrafici</Row>
                                <Row><Text strong style={{ color: "#FFFFFF" }}>{this.props.intl.formatMessage({ id: "utente.statoUtente.attivo" })}</Text>L'utente ha completato l'attivazione e ha inserito i dati anagrafici</Row>
                                <Row><Text strong style={{ color: "#FFFFFF" }}>{this.props.intl.formatMessage({ id: "utente.statoUtente.nonAttivo" })}</Text>L'utente è stato disabilitato e non può accedere al sistema</Row>
                            </Space>
                        }
                    ><InfoCircleOutlined />
                    </Tooltip></Space>,
                dataIndex: "statoUtente",
                dataType: "number",
                filters:
                    [{ value: StatoUtenteEnum.InAttivazione, text: this.props.intl.formatMessage({ id: "utente.statoUtente.inAttivazione" }) },
                    { value: StatoUtenteEnum.AttivoInAttesaDati, text: this.props.intl.formatMessage({ id: "utente.statoUtente.attivoInAttesaDati" }) },
                    { value: StatoUtenteEnum.Attivo, text: this.props.intl.formatMessage({ id: "utente.statoUtente.attivo" }) },
                    { value: StatoUtenteEnum.Disattivo, text: this.props.intl.formatMessage({ id: "utente.statoUtente.nonAttivo" }) },
                    ],
                render: (value: any) =>
                    <>
                        {value == StatoUtenteEnum.Attivo && this.props.intl.formatMessage({ id: "utente.statoUtente.attivo" })}
                        {value == StatoUtenteEnum.Disattivo && this.props.intl.formatMessage({ id: "utente.statoUtente.nonAttivo" })}
                        {value == StatoUtenteEnum.InAttivazione && this.props.intl.formatMessage({ id: "utente.statoUtente.inAttivazione" })}
                        {value == StatoUtenteEnum.AttivoInAttesaDati && this.props.intl.formatMessage({ id: "utente.statoUtente.attivoInAttesaDati" })}
                    </>
            },
        ];

        return (
            <div>
                <Row justify="space-between" align="middle">
                    <Title level={2}>
                        <div>{this.title}</div>
                    </Title>
                    <Link to={this.detailUrl}>
                        <Button type="primary">{msg_new}</Button>
                    </Link>
                </Row>

                <ZuTable
                    baseUrl={this.url}
                    columns={columns}
                    rowKey={(record: any) => {
                        return record.id;
                    }}
                    style={{
                        backgroundColor: "white",
                    }}
                ></ZuTable>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.account } },
    { ...AccountStore.actionCreators }
)(injectIntl(UsersComponent));
