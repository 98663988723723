// weird gotcha - install history v4.10.1
// see open issue: https://github.com/supasate/connected-react-router/issues/312#issuecomment-647082777
import { createBrowserHistory, History } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware, connectRouter, RouterState } from "connected-react-router";
import {reducers } from './';

export const history = createBrowserHistory();

// combineReducers will be handled internally by configureStore
const rootReducer = (history: History<any>) => ({
  ...reducers,
  router: connectRouter(history)
});

const preloadedState = {};
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: rootReducer(history),
  preloadedState
});

export interface I_AppState {
  articles: { articleCount: number };
  selection: { selected: boolean };
  router: RouterState;
}



// import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
// import { History } from 'history';
// import { ApplicationState, reducers } from './';

// export default function configureStore(history: History, initialState?: ApplicationState) {
//     const middleware = [
//         thunk,
//         routerMiddleware(history)
//     ];

//     const rootReducer = combineReducers({
//         ...reducers,
//         router: connectRouter(history)
//     });

//     const enhancers = [];
//     const windowIfDefined = typeof window === 'undefined' ? null : window as any;
//     if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//         enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
//     }

//     return createStore(
//         rootReducer,
//         initialState,
//         compose(applyMiddleware(...middleware), ...enhancers)
//     );
// }


// import { configureStore } from '@reduxjs/toolkit';
// import { createBrowserHistory } from 'history';
// import { reducers } from './';

// export const history = createBrowserHistory();
    
// export const store = configureStore({
//   reducer: {
//     ...reducers,
//     // router: connectRouter(history),
//   },
//   middleware: [routerMiddleware(history)],
  
// });

// export default store;


