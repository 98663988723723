import * as LayoutStore from './layoutStore';
import * as AccountStore from './accountStore';
import * as CarrelloStore from './carrelloStore';
import * as CataloghiStore from './cataloghiStore';

// The top-level state object
export interface ApplicationState {
    layout: LayoutStore.LayoutState | undefined;
    account: AccountStore.AccountState | undefined;
    carrello: CarrelloStore.CarrelloState | undefined;
    cataloghi: CataloghiStore.CatalogoState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    layout: LayoutStore.reducer,
    account: AccountStore.reducer,
    carrello: CarrelloStore.reducer,
    cataloghi: CataloghiStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
