import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore';
import { Card, Col, Row, Space, Typography, Image, Divider, Form, InputNumber, Button, List, Affix, message, Empty, Spin, Popconfirm, Result, Input } from 'antd';
import { DeleteOutlined, CheckOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import ZuFormDetail from '../Zerouno/ZuFormDetail';
import noImg from './noImg.png';
import CatStatisticheSelect from './select/CatStatisticheSelect';
import { debounce } from '../utils/utils';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const BASEPATHIMMAGINI = "StaticFiles";

class CarrelloComponent extends ZuFormDetail {
    state = {
        // articoli: [],
        loading: false,
        loadingSingleBtn: "",
        visibleSinglePop: "",
        visibleUpdateQta: "",
        qtaUpdate: 0,
        loadingPreventivo: false,
        successResult: false,
        //noteArticoli: [] as any,
    }

    async componentDidMount() {
        var { carrello } = this.props;
        // this.setState({ articoli: carrello });        
        var res = await fetch("/api/Carrelli/GetCarrello");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCarrello([]);
                return;
            } else {
                carrello = json.articoliQta;
                // descrizioneLinea = json.descrizione;
                this.props.setCarrello(carrello);
                return;
            }
        }
        //this.setNote = debounce(this.setNote, 400);
    }

    richiediPreventivo = async () => {
        this.setState({ loadingPreventivo: true })
        const articoli = Object.values(this.props.carrello || []);
        let model = [] as any;
        (articoli || []).map((x: any) => {
            // let newList = JSON.parse(JSON.stringify(this.state.noteArticoli));
            // var index = newList.findIndex((y: any) => x.id === y.id);
            // let nota = ""
            // if (index !== -1) {
            //     nota = newList[index].nota;
            // }
            let d = { idArticolo: x.idArticolo, descrizione: x.descrizione, quantita: x.quantita, idCatStatistica: x.idCatStatistica, note: x.note };
            model.push(d);
        })

        const response = await fetch("api/Carrelli/GetPreventivoCarrello", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                listaArticoli: model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            // notification.success({ message: 'Richiesta preventivo inviata correttamente' })
            setTimeout(() => this.aggiornaCarrello(true), 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingPreventivo: false })
        }

    }

    calcolaTotali = (articoli: any) => {
        let qtaTot = 0;
        let articoliTot = 0;
        articoli.forEach((x: any) => { articoliTot += 1; qtaTot += x.quantita });
        return { "articoliTot": articoliTot, "qtaTot": qtaTot };
    }

    aggiornaCarrello = async (successResult = false) => {
        var res = await fetch("/api/Carrelli/GetCarrello");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCarrello([]);
                this.setState({ loading: false, loadingSingleBtn: "", visibleUpdateQta: "", loadingPreventivo: false, successResult: successResult });
                return;
            } else {
                let carrello = json.articoliQta;
                // descrizioneLinea = json.descrizione;
                this.props.setCarrello(carrello);
                this.setState({ loading: false, loadingSingleBtn: "", visibleUpdateQta: "", loadingPreventivo: false });
                return;
            }
        }
    }

    rimuoviArticolo = async (idArticolo: any, id: any) => {
        this.setState({ loadingSingleBtn: id });
        let model = { idArticolo: idArticolo, id: id, quantita: 0 };

        const response = await fetch("api/Carrelli/RemoveArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCarrello, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingSingleBtn: "" });
        }
    }

    modificaArticolo = async (value: any, idArticolo: any, id: any, idCatStatistica: any, note: any) => {
        this.setState({ loading: true });
        let model = { idArticolo: idArticolo, quantita: value, id: id, idCatStatistica: idCatStatistica, note: note };

        const response = await fetch("api/Carrelli/UpdateArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            //data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCarrello, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loading: false });
        }
    }

    // setNote = (testo: any, id: any) => {
    //     let n = { id: id, nota: testo };
    //     const noteArticoli = this.computeNoteList(n);
    //     this.setState({ noteArticoli: noteArticoli });
    // }

    // computeNoteList = (values: any) => {
    //     let newList = JSON.parse(JSON.stringify(this.state.noteArticoli));
    //     var index = newList.findIndex((x: any) => values.id === x.id);
    //     if (index !== -1) {
    //         newList[index] = values;
    //     } else {
    //         newList.push(values);
    //     }
    //     return newList;
    // }

    render() {
        const { loading, visibleSinglePop, loadingSingleBtn, visibleUpdateQta, loadingPreventivo, successResult } = this.state;
        const articoli = Object.values(this.props.carrello || []);
        const totali = this.calcolaTotali(articoli);
        return (
            <Spin spinning={loading}>
                <Card>
                    {successResult ?
                        <Result
                            status="success"
                            title="Richiesta preventivo inviata con successo"
                        />
                        :
                        (articoli.length == 0 ?
                            <Empty description="Il tuo carrello è vuoto"></Empty>
                            : <Row>
                                <Col md={15} >
                                    <List >
                                        {(articoli || []).map((articolo: any) =>
                                            <List.Item key={articolo.id + articolo.idCatStatistica}>
                                                <Col md={4}>
                                                    <Image                                                       
                                                        preview={false}
                                                        width={'100%'}
                                                        src={articolo?.immaginedefault ? articolo?.immaginedefault : (articolo?.immagini?.length > 0 ? `${BASEPATHIMMAGINI}/${articolo?.immagini[0]}` : noImg)}
                                                        style={{ aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center center' }}
                                                    />
                                                </Col>
                                                <Col md={19} offset={1}>
                                                    <List.Item.Meta
                                                        title={
                                                            <Row justify='space-between'>
                                                                <Space>
                                                                    {articolo.idArticolo}
                                                                </Space>
                                                                <Space align='end'>
                                                                    <Popconfirm
                                                                        title="Rimuovera l'articolo dal carrello?"
                                                                        onConfirm={() => this.rimuoviArticolo(articolo.idArticolo, articolo.id)}
                                                                        onCancel={() => this.setState({ visibleSinglePop: "" })}
                                                                        okButtonProps={{ loading: loadingSingleBtn === articolo.id }}
                                                                        visible={visibleSinglePop === articolo.id}
                                                                    >
                                                                        <Button type='link' onClick={() => this.setState({ visibleSinglePop: articolo.id })}>Rimuovi <DeleteOutlined /></Button>
                                                                    </Popconfirm>
                                                                </Space>
                                                            </Row>
                                                        }
                                                        description={articolo.descrizione}
                                                    />
                                                    <Form layout="vertical" size='small' style={{ marginTop: 16 }} initialValues={{ colore: articolo.idCatStatistica }}>
                                                        <Row>
                                                            <Col md={24} flex={"150px"}>
                                                                <Form.Item label="Quantità" name="quantita" >
                                                                    {visibleUpdateQta == articolo.idArticolo ?
                                                                        <Space size={[0, 0]}>
                                                                            <InputNumber
                                                                                size="small"
                                                                                min={1}
                                                                                max={100000}
                                                                                value={this.state.qtaUpdate}
                                                                                onChange={(value: any) => this.setState({ qtaUpdate: value })}
                                                                            />
                                                                            <Button onClick={() => this.modificaArticolo(this.state.qtaUpdate, articolo.idArticolo, articolo.id, articolo.idCatStatistica, articolo.note)} icon={<CheckOutlined style={{ fontSize: 12 }} />} />
                                                                        </Space>
                                                                        :
                                                                        <Space>
                                                                            <Button disabled={articolo.quantita === 1} icon={<MinusOutlined />} onClick={() => this.modificaArticolo(articolo.quantita - 1, articolo.idArticolo, articolo.id, articolo.idCatStatistica, articolo.note)} />
                                                                            <Button type="text" style={{ width: 60 }} onClick={() => this.setState({ visibleUpdateQta: articolo.idArticolo, qtaUpdate: articolo.quantita })}>{articolo.quantita}</Button>
                                                                            <Button icon={<PlusOutlined />} onClick={() => this.modificaArticolo(articolo.quantita + 1, articolo.idArticolo, articolo.id, articolo.idCatStatistica, articolo.note)} />
                                                                        </Space>
                                                                    }
                                                                </Form.Item>
                                                            </Col>
                                                            <Col md={24} flex={"auto"}>
                                                                <Form.Item label="Colore" name="colore" >
                                                                    <CatStatisticheSelect onChange={(e: any) => this.modificaArticolo(articolo.quantita, articolo.idArticolo, articolo.id, e, articolo.note)} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={24} >
                                                                <Form.Item label="Note" name="note" >
                                                                    {/* <TextArea autoSize={{ minRows: 1, maxRows: 4 }} onChange={(e: any) => this.setNote(e.target.value, articolo.id)} /> */}
                                                                    <Paragraph
                                                                        editable={{
                                                                            tooltip: 'clicca per modificare',
                                                                            onChange: (e: any) => this.modificaArticolo(articolo.quantita, articolo.idArticolo, articolo.id, articolo.idCatStatistica, e),
                                                                            triggerType: ['icon', 'text'],
                                                                            maxLength: 1000,
                                                                        }}
                                                                    >
                                                                        {articolo.note}
                                                                    </Paragraph>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                                {/* <Col md={4}>
                                                <Popconfirm title="Rimuovera l'articolo dal carrello?" onConfirm={() => this.rimuoviArticolo(articolo.idArticolo)}>
                                                    <Button type='link'>Rimuovi <DeleteOutlined /></Button>
                                                </Popconfirm>
                                            </Col> */}
                                            </List.Item>
                                        )}
                                    </List>
                                </Col>
                                <Col md={8} offset={1}>
                                    <Affix offsetTop={20}>
                                        <Row justify="space-between">
                                            <Space>
                                                <Title level={5}>Articoli totali:</Title>
                                            </Space>
                                            <Space align='end'>
                                                <Title level={5}>{totali.articoliTot}</Title>
                                            </Space>
                                        </Row>
                                        <Row justify="space-between">
                                            <Space>
                                                <Text>Numero pezzi:</Text>
                                            </Space>
                                            <Space align='end'>
                                                <Text>{totali.qtaTot}</Text>
                                            </Space>
                                        </Row>
                                        <Divider />
                                        <Button size='large' style={{ width: '100%' }} onClick={this.richiediPreventivo} type="primary" loading={loadingPreventivo}>Richiedi preventivo</Button>
                                    </Affix>
                                </Col>
                            </Row>)
                    }
                </Card>
            </Spin>
        );
    };
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators }
)(CarrelloComponent);