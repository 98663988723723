import * as React from 'react';
import { notification, message } from 'antd';

const msg_unableToLoadData = "Impossibile caricare i dati";
const msg_saveOk = "Operazione completata";
const msg_saveError = "Operazione non riuscita";
const msg_unexpectedError = "Si è verificato un errore imprevisto: contattare l'amministratore";

const msg_eliminazioneCompletata = "Eliminazione completata";
const msg_eliminazioneNonRiuscita = "Eliminazione non riuscita";

//interface IZuFormDetailProps {
//    baseUrl: string;
//    form?: any;
//    match?: any
//}

//export interface IZuFormDetailState {
//    loading?: boolean;
//}

export default class ZuFormDetail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.isNew = !(props.match && props.match.params.id) && !props.id;
    }

    isNew: boolean;


    state: any = {
        loading: false,
    };

    getDefaults = async () => {
        this.setState({ loading: true });

        const url = `${this.props.baseUrl}/Defaults`;
        const response = await fetch(url, {});
        const res = await response;
        this.setState({ loading: false });

        if (res.ok) {
            let data = (await res.json()) || {};
            //console.log("Default data:", data);
            return await data;
        }

        //console.log(res);
        notification.error({ message: msg_unableToLoadData, description: msg_unexpectedError, duration: 0 });
        return null;
    }

    load = async (key: any) => {
        this.setState({ loading: true });

        const url = `${this.props.baseUrl}/${key}`;
        const response = await fetch(url, {});
        const res = await response;
        this.setState({ loading: false });

        if (res.ok) {

            let data = (await res.json()) || {};
            //console.log("Loaded data:", data);
            return await data;
        }

        notification.error({ message: msg_unableToLoadData, description: msg_unexpectedError, duration: 0 });
        return null;
    }

    submit = async (model: any, url?: string) => {
        this.setState({ loading: true });

        let submitUrl = url || this.props.baseUrl;
        const response = await fetch(submitUrl, {
            method: this.isNew ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(model)
        });
        const res = await response;
        this.setState({ loading: false });

        if (res.ok) {
            message.success(msg_saveOk);
            return res;
        }

        notification.error({ message: msg_saveError, description: msg_unexpectedError, duration: 0 });
        return res;
    }

    //TODO: Uniformare con quella di ZuTable
    delete = async (id: any) => {
        this.setState({ loading: true });

        const url = this.props.deleteUrl || `${this.props.baseUrl}/${id}`;
        const response = await fetch(url, { method: 'DELETE' });
        const res = await response;

        if (res.ok) {
            message.success(msg_eliminazioneCompletata);
        }
        else
            message.error(msg_eliminazioneNonRiuscita);

        this.setState({ loading: false });
    }

};

