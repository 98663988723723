import { ConfigProvider } from 'antd';
import * as React from 'react';
import { Route } from 'react-router';

import * as moment from 'moment';
import './App.less';

// redux
import { connect } from 'react-redux';
import { ApplicationState } from './store';
import * as AccountStore from './store/accountStore';
import * as CarrelloStore from './store/carrelloStore';
import * as CataloghiStore from './store/cataloghiStore';

// components
import HomePublic from './components/Home';
import HomePrivate from './components/HomePrivate';
import Login from './components/identity/Login';
import Layout from './components/Layout';
import UserDetail from './components/UserDetail';
import Users from './components/Users';
import UserCambioPassword from './components/UserCambioPassword';
import ZuAccountHelper from './Zerouno/ZuAccountHelper';
import ArticoliDetail from './components/ArticoliDetail';
import Catalogo from './components/Catalogo';
import Carrello from './components/Carrello';
import Cataloghi from './components/Cataloghi';
import ContenutiPromozionali from './components/ContenutiPromozionali';
import ContenutiPromozionaliDetail from './components/ContenutiPromozionaliDetail';

// traduzioni
import { FormattedMessage, IntlProvider } from 'react-intl';
// traduzioni.stringhe
import intl_it from './lang/it.json';
import intl_en from './lang/en.json';
import intl_fr from './lang/fr.json';
// traduzioni.formati
import itIT from 'antd/es/locale-provider/it_IT';
import enUS from 'antd/es/locale-provider/en_US';
import frFR from 'antd/es/locale-provider/fr_FR';
// traduzioni.datime picker (default en)
import 'moment/locale/it';
import 'moment/locale/fr';
import UserPasswordDimenticata from './components/UserPasswordDimenticata';
import UserResetPassword from './components/UserResetPassword';
import UserAttivaAccount from './components/UserAttivaAccount';
import UserRegistrazione from './components/UserRegistrazione';
import UserCompletaDatiUtente from './components/UserCompletaDatiUtente';
import UserCompletaDatiUtenteResult from './components/UserCompletaDatiUtenteResult';


class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    state = {
        locale: itIT,
        localeMessages: intl_it,
        lang: "it",
        languages: ["it", "en", "fr"]
    }

    componentDidMount() {
        this.detectLanguage();
    }

    detectLanguage = () => {
        // se lho settato in precedenza lo recupero
        var l = localStorage.getItem('lang');
        if (l) {
            this.changeLocale(l, true);
            return;
        }

        //se non lho mai settato lo recupero dal browser
        for (var index in navigator.languages) {
            var lang = navigator.languages[index];
            var code = lang.substring(0, 2).toLocaleLowerCase();
            if (this.state.languages.indexOf(code) > -1) {
                this.changeLocale(code, true);
                return;
            }
        }

        //fallback
        this.changeLocale("it", true);
    }

    changeLocale = (newLang: string, skipLocalStorage?: boolean) => {

        if (!newLang) {
            // clear => rimuovo preferenza e rilancio detect
            localStorage.removeItem('lang');
            this.detectLanguage();
            return;
        }

        var newState: any;

        var l = newLang.length > 2 ? newLang.substring(0, 2) : newLang;


        // se invariato non faccio niente
        if (this.state.lang === l) return;


        switch (l) {
            case 'en':
                newState = {
                    locale: enUS,
                    localeMessages: { ...intl_it, ...intl_en } //fallback su it
                };
                break;

            case 'fr':
                newState = {
                    locale: frFR,
                    localeMessages: { ...intl_it, ...intl_fr } //fallback su it
                };
                break;

            case 'it':
            default:
                newState = {
                    locale: itIT,
                    localeMessages: intl_it,
                };
                break;
        }

        // ricordarsi di aggiungere import moment/locale/xx
        moment.locale(l);
        document.documentElement.lang = l;

        this.setState({ ...newState, lang: l });

        if (skipLocalStorage !== true) {
            // setto scelta nel local storage (solo per cambi manuali)
            localStorage.setItem('lang', l);
        }
    };

    getCarrelloUtente = async () => {
        var { carrello } = this.props;
        if (!carrello) {
            var res = await fetch("/api/Carrelli/GetCarrello");
            var json = res.ok && await res.json();
            if (json) {
                if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                    //Risposta vuota no carrello
                    this.props.setCarrello([]);
                    return;
                } else {
                    carrello = json.articoliQta;
                    // descrizioneLinea = json.descrizione;
                    this.props.setCarrello(carrello);
                    return;
                }
            }

            // retry
            window.setTimeout(async () => {
                await this.getCarrelloUtente();
            }, 1000);
        }
    }

    getCataloghiUtente = async () => {
        var { cataloghi } = this.props;
        let c = [] as any
        if (!cataloghi) {
            var res = await fetch("/api/Cataloghi/GetCataloghi");
            var json = res.ok && await res.json();
            if (json) {
                if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                    //Risposta vuota no carrello
                    this.props.setCataloghi([]);
                    return;
                } else {
                    cataloghi = json;
                    // descrizioneLinea = json.descrizione;
                    this.props.setCataloghi(cataloghi);
                    return;
                }
            }

            // retry
            window.setTimeout(async () => {
                await this.getCataloghiUtente();
            }, 1000);
        }
    }


    render = () => {
        const { locale, lang, localeMessages } = this.state;

        /* IDENTITY*/
        const { user } = this.props;
        if (!user || user.isInitialState) {
            // initializing
            return (<>
                <div hidden><ZuAccountHelper checkIdentityOnMount={true} /></div>
                <div className="centered">
                    {/*<Spin size="large" />*/}
                </div>
            </>);
        }
        if (!user.isAuthenticated) return (
            <>
                <ConfigProvider locale={locale}>
                    <IntlProvider messages={localeMessages} locale={lang} defaultLocale="it" >
                        <Layout changeLocale={this.changeLocale} defaultLang={this.state.lang} languages={this.state.languages}>
                            <div hidden><ZuAccountHelper checkIdentityOnMount={true} /></div>
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/' component={Login} />
                            <Route exact path='/UserCompletaDatiUtente' component={Login} />
                            <Route exact path='/UserCompletaDatiUtenteResult' component={UserCompletaDatiUtenteResult} />

                            <Route path='/UserPasswordDimenticata' component={UserPasswordDimenticata} />
                            <Route exact path='/ResetPassword' component={UserResetPassword} />
                            <Route exact path='/AccountActivation' component={UserAttivaAccount} />
                            <Route exact path='/UserRegistrazione' component={UserRegistrazione} />

                            {/* <Route path="/ArticoliDetail/:id?" component={ArticoliDetail} />
                            <Route path="/Carrello" component={Carrello} />
                            <Route path="/ListePreferiti" component={ListePreferiti} /> */}
                        </Layout>
                    </IntlProvider>
                </ConfigProvider>
            </>
        );
        /* IDENTITY*/
        this.getCarrelloUtente();
        this.getCataloghiUtente();
        return (

            <ConfigProvider locale={locale}>
                <IntlProvider messages={localeMessages} locale={lang} defaultLocale="it">
                    {/*<div hidden><ZuAccountHelper checkIdentityOnMount={true} /></div>*/}
                    <Layout changeLocale={this.changeLocale} defaultLang={this.state.lang} languages={this.state.languages}>
                        <Route exact path='/login' component={Login} />

                        <Route exact path='/Users' component={Users} />
                        <Route path='/UserDetail/:id?' component={UserDetail} />
                        <Route path='/UserCambioPassword' component={UserCambioPassword} />

                        <Route exact path='/' component={HomePrivate} />
                        <Route exact path='/Catalogo' component={Catalogo} />
                        <Route path="/ArticoliDetail/:id?" component={ArticoliDetail} />
                        <Route path="/Carrello" component={Carrello} />
                        <Route path="/IMieiCataloghi" component={Cataloghi} />

                        <Route exact path="/ContenutiPromozionali" component={ContenutiPromozionali} />
                        <Route path="/ContenutiPromozionaliDetail/:id?" component={ContenutiPromozionaliDetail} />

                        <Route exact path='/UserCompletaDatiUtente' component={UserCompletaDatiUtente} />
                    </Layout>
                </IntlProvider>
            </ConfigProvider>
        );
    }
}


export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(App);
