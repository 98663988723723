import * as React from 'react';
import ZuSelectElasticFaro from './ZuSelectElasticFaro';
import { Typography } from "antd";
const { Text } = Typography;

export default class CatStatisticheSelect extends React.Component<any, any> {

    render() {
        return (
            <ZuSelectElasticFaro
                {...this.props}
                url="api/Query/CatStatistiche"
                textColumn="descrizione"
                valueColumn="id"
                pageSize={200}
            />

        );
    }

}


