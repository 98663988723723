import * as React from 'react';
import { Input, Table, Popconfirm, message, Tooltip } from 'antd';

import { TableProps, ColumnProps } from 'antd/lib/table';
import { SearchDropdown } from "./SearchDropdown";
import { composeFilterString } from './helpers';
import { PaginationConfig } from 'antd/lib/pagination';
import { DeleteOutlined  } from '@ant-design/icons';
import { ColumnsType, ColumnGroupType } from 'antd/lib/table/interface';

const { Search } = Input;

const msg_eliminazioneCompletata = "Eliminazione completata";
const msg_confermiCancellazione = "Confermi la cancellazione?";
const msg_eliminazioneNonRiuscita = "Eliminazione non riuscita";
const msg_erroreCaricamento = "Caricamento dati non riuscito";
const msg_search = "Ricerca";

interface ZuTableProps<T> extends TableProps<T> {
    baseUrl: string,
    queryUrl?: string,
    deleteUrl?: string,
    remoteFiltering: boolean,
    renderDeleteAction: boolean,
    afterDataFetched(data: any): any,
    pageSize: number,
    filter: string,
    searchBarRender?: (globalSearch: any) => {},
}

interface ZuTableState {
    filters: any[],
    loading: boolean,
    rows: any[],
    pagination: PaginationConfig,
    sorter: any,
    globalFilterValue: string,
};

/*
    Versione: 22.04.12
    Changelog:
        22.04.12 MB Update antd 4.19.5, bug fix filtri e paginazione
        21.10.15 MB Aggiunta proprietà per filtro aggiuntivo in lettura dati
        20.12.15 fix pagination
        20.05.27 antd v4
*/

export default class ZuTable<T> extends React.Component<ZuTableProps<T>, ZuTableState> {

    constructor(props: ZuTableProps<T>) {
        super(props)

        // Verifica se è richiesto un ordinamento iniziale
        let sorter = {};
        this.selectManyColumns(props.columns).forEach((item: any) => {
            if (item.defaultSortOrder) {
                sorter = { field: item.dataIndex || item.key, order: item.defaultSortOrder };
            }
        });

        // Verifica se è richiesto un filtro iniziale
        let filters = {} as any;
        this.selectManyColumns(props.columns).forEach((item: any) => {
            if (item.defaultFilteredValue) {
                filters[item.dataIndex || item.key] = item.defaultFilteredValue;
            }
        });

        this.state = {
            filters,
            loading: false,
            rows: [],
            pagination: { current: 1, pageSize: this.props.pageSize, showSizeChanger: true  },
            sorter,
            globalFilterValue: "",
        }
        //console.log("ctor", this.props, this.state);
    }

    static defaultProps = {
        remoteFiltering: true,
        renderDeleteAction: true,
        afterDataFetched: () => { },
        pageSize: 10,
        filter: null,
    }

    componentDidMount() {
        this.fetch();
    }


    private selectManyColumns<T>(columns: ColumnsType<T> | undefined) {

        let ret: ColumnsType<T> | undefined = [];

        if(columns != undefined){
            for (let index = 0; index < columns.length; index++) {
                const e = columns[index];
                const children = (e as ColumnGroupType<T>).children;
                if(children)
                {
                    ret.push(...this.selectManyColumns(children));
                }
                else{
                    ret.push(e);
                }
            }
        }

        return ret;
    }
 

    private getGlobalFilterColumns() {
        return this.selectManyColumns(this.props.columns)!.filter((i: any) => i.globalFilter);
    }

    
    fetch = async () => {
        this.setState({ loading: true });

        const { pagination, sorter, filters, globalFilterValue } = this.state;
        const additionalFilter = this.props.filter;

        // Filtri per colonna
        let colString = Object.keys(filters).map((item: any) => {
            let f = (filters[item] || []).map((i: any) => {
                return composeFilterString(this.selectManyColumns(this.props.columns).find((i: any) => i.dataIndex == item), i);
            });
            let filterStr = f.filter((i: any) => i).join(" or ");
            if (filterStr) filterStr = `(${filterStr})`
            return filterStr;
        }).filter(i => i).join(" and ");

        // Filtro globale sulle colonne con globalFilter="true"
        let globalString = "";
        if ((globalFilterValue || "").toString().trim()) {
            globalString = (this.getGlobalFilterColumns() || [])
                .map((item: any) => composeFilterString(item, globalFilterValue))
                .filter(i => i).join(" or ");
        }

        // Composizione del filtro complessivo
        let filterStr = [colString, globalString].filter(i => (i || "").toString().trim()).join(") and (");
        if (filterStr) filterStr = `(${filterStr})`

        // Aggiunta filtro opzionale da props
        if (additionalFilter) {
            if (filterStr)
                filterStr = `(${additionalFilter}) and (${filterStr})`;
            else
                filterStr = additionalFilter;
        }

        let params = {
            take: pagination.pageSize,
            skip: ((pagination.current || 1) - 1) * (pagination.pageSize || 0),
            orderby: `${sorter.field || ''}${(sorter.order || '') == 'descend' ? ' desc' : ''}`,
            filter: filterStr,
        }

        //console.log("fetch", params);
        const url = this.props.queryUrl || `${this.props.baseUrl}/Query`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...params
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            data = await res.json() || {};

            data = this.props.afterDataFetched(data) || data;

            const pagination = { ...this.state.pagination, total: data.totalRows };

            this.setState({ loading: false, rows: data.rows, pagination });
        }
        else {
            message.error(msg_erroreCaricamento);
            this.setState({ loading: false });
        }
    }

    //TODO: Uniformare con quella di ZuFormDetail
    delete = async (id: any) => {
        this.setState({ loading: true });

        const url = this.props.deleteUrl || `${this.props.baseUrl}/${id}`;
        const response = await fetch(url, { method: 'DELETE' });
        const res = await response;

        if (res.ok)
        {
            message.success(msg_eliminazioneCompletata);
            this.fetch();
        }
        else
            message.error(msg_eliminazioneNonRiuscita);

        this.setState({ loading: false });
    }

    renderDeleteAction = (text: any, record: any) => (
        <span>
            <Popconfirm title={msg_confermiCancellazione} onConfirm={() => this.delete(record.id)}>
                <a href="#"><DeleteOutlined/></a>
            </Popconfirm>
        </span>
    );

    handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        //console.log("handleTableChange", pagination, filters, sorter, this.state.filters);

        if (this.props.onChange) this.props.onChange(pagination, filters, sorter, extra);

        const pager = { ...this.state.pagination, current: pagination.current, pageSize: pagination.pageSize };
        const filter = { ...this.state.filters, ...filters  };

        this.setState({
            pagination: pager,
            sorter: { ...sorter },
            filters: filter,
        }, 
            () => {if (this.props.remoteFiltering) { this.fetch(); }
        });
    };

    handleGlobalFilterSubmit = (value: any) => {
        this.setState({ globalFilterValue: value }, this.fetch);
    }


    render() {

        const actionsColumn: ColumnProps<T> = {
            render: this.renderDeleteAction,
        };

        let searcher = new SearchDropdown();

        // applicazione dei filtri ricorsiva sui children
        const cb = (col: any) => {
            const children = col.children;
            if (children) {
                return { ...col, children: children.map(cb) };
            }
            else {
                let searchProps = col.filter ? searcher.getColumnSearchProps(col.dataIndex, col.title, col.dataType) : null;
                return { ...col, ...searchProps };
            }
        }
        let columns = (this.props.columns || []).map(cb);

        columns = this.props.renderDeleteAction ? [...columns, actionsColumn] : columns;

        // Bug Table Antd (3.25.1): key non sarebbe necessario se specificato dataIndex, ma in 
        // alcuni casi non funziona l'ordinamento in presenzas di filtri se key non è valorizzato
        columns.forEach(i => { i.key = i.key || i.dataIndex });

        const globalSearchHint = `${msg_search} ` + this.getGlobalFilterColumns().map(i => i.title).join(", ");

        let globalSearch = null;
        
        if (this.getGlobalFilterColumns().length > 0) {
            globalSearch = 
                <Tooltip
                    trigger="hover"
                    title={globalSearchHint}
                    placement="topLeft">
                    <Search
                        allowClear
                        autoFocus
                        placeholder={msg_search}
                        enterButton
                        onSearch={this.handleGlobalFilterSubmit}
                        style={{ width: 300 }} />
                </Tooltip>;
        }

        if (this.props.searchBarRender) {
            globalSearch = this.props.searchBarRender(globalSearch);
        }

        return (
            <>
                <div style={{ marginBottom: 8 }}>{globalSearch}</div>

                <Table
                    {...this.props as any}
                    loading={this.state.loading}
                    dataSource={this.state.rows}
                    columns={columns}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                ></Table>

            </>
        )
    };
};

