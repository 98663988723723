import { Button, Card, Col, Form, Input, message, notification, Result, Row, } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import ZuFormDetail from "../Zerouno/ZuFormDetail";
import { FormInstance } from "antd/lib/form";
import { injectIntl } from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";
import { msg_operationError, msg_standardValidationError, msg_unexpectedError } from "../utils/constants";
import { Link } from "react-router-dom";

const search = window.location.search;
const params = new URLSearchParams(search);
const code = params.get('code');
const username = params.get('username');

class UserCambioPasswordComponent extends ZuFormDetail {
    readonly title = this.props.intl.formatMessage({ id: "utente.utente" });
    formRef = React.createRef<FormInstance>();
    recaptchaRef = React.createRef<any>();

    static defaultProps = {

    };

    state = {
        passwordChanged: false,
        confirmChanged: false,
        captcha: false,
        loading: false,
        resultSuccess: false,
    };

    componentDidMount() {

    }

    componentDidUpdate() {
        // This method is called when the route parameters change
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });
        let data = {
            ...values,
            RecaptchaToken: this.recaptchaRef?.current?.getValue()
        };
        data.code = code;
        const response = await fetch("api/Account/ResetPassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...data
            })
        });
        const res = await response;

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            // notification.success({ message: "Password ripristinata con successo" })
            // window.location.href = "/login";
            // window.history.back();
            this.setState({ loading: false, resultSuccess: true });
        }
        else {
            //reset captcha
            this.recaptchaRef.current.reset();
            let json = await response?.json();
            if (typeof json.message === 'string' ||json.message instanceof String) {
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message}</>,
                    "duration": 60
                });
            }
            else{
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message.map((x:any)=> <p>{x.description}</p>)}</>,
                    "duration": 60
                });
            }
            
            this.setState({ loading: false });
        }
    };

    handlePasswordBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ passwordChanged: true });
    };
    handleConfirmBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ confirmChanged: true });
    };
    onReCaptchaChange = (value: any) => {
        this.setState({
            captcha: value,
        });
    };


    render() {
        const { passwordChanged, confirmChanged, resultSuccess } = this.state;

        const msgPasswordNonCoincidono = this.props.intl.formatMessage({ id: "utente.msgPasswordNonCoincidono" });
        const msgPasswordNonValida = this.props.intl.formatMessage({ id: "utente.msgPasswordNonValida" });
        const msgEmailValida = this.props.intl.formatMessage({ id: "utente.msgEmailValida" });
        return (
            <>
                <Card>
                    {resultSuccess ?
                        <Result
                            status="success"
                            title="Password ripristinata con successo"
                            extra={[
                                <Link to="/login">
                                    <Button type="primary" key="console">
                                        Effettua login
                                    </Button>
                                </Link>
                            ]}
                        />
                        :
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.handleSubmit}
                            initialValues={{ email: username }}
                        >
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item
                                        label={this.props.intl.formatMessage({ id: "utente.username" })}
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item
                                        label={this.props.intl.formatMessage({ id: "utente.password" })}
                                        hasFeedback
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                                min: 8,
                                                message:
                                                    msgPasswordNonValida,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            visibilityToggle={passwordChanged}
                                            onBlur={this.handlePasswordBlur}
                                            autoComplete="new-password"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item
                                        label={this.props.intl.formatMessage({ id: "utente.confermaPassword" })}
                                        hasFeedback
                                        name="confirmPassword"
                                        dependencies={["password"]}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    var password = getFieldValue(
                                                        "password"
                                                    );
                                                    if (password !== value) {
                                                        return Promise.reject(
                                                            msgPasswordNonCoincidono
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            visibilityToggle={confirmChanged}
                                            onBlur={this.handleConfirmBlur}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24} style={{ paddingBottom: 16 }}>
                                    <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        sitekey="6Ldb4eUeAAAAALyj4GvaEuu4xjF2Xj-6dXF3ak7W"
                                        onChange={this.onReCaptchaChange}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={{ offset: 8, span: 8 }} sm={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={this.state.loading}>{this.props.intl.formatMessage({ id: "global.conferma" })}</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Card>
            </>
        );
    }
}

export default injectIntl(
    connect()(UserCambioPasswordComponent)
)
