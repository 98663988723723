import { Action, Reducer } from 'redux';

const hideMenuType = 'HIDE_MENU';
const setTitleType = 'SET_TITLE';
const initialState = { hidden: false, title: "" };


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LayoutState {
    hidden?: boolean,
    title: string,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface HideMenuAction { type: typeof hideMenuType }
export interface SetTitleAction { type: typeof setTitleType, title: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = HideMenuAction | SetTitleAction; // | AnotherAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    hideMenu: () => ({ type: hideMenuType } as HideMenuAction),
    setTitle: (title: string) => ({ type: setTitleType, title } as SetTitleAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<LayoutState> = (state: LayoutState | undefined, incomingAction: Action): LayoutState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case hideMenuType:
            return { ...state, hidden: true };
        case setTitleType:
            return { ...state, title: action.title };
        default:
            return state;
    }
};




//export const reducer = (state: any, action: any) => {
//    state = state || initialState;

//    if (action.type === hideMenuType) {
//        return { ...state, hidden: true };
//    };

//    return state;
//};

//export const mapStateToProps = (state: any) => {
//    return {
//        hidden: state.layout.hidden
//    };
//};

//export const mapDispatchToProps = (dispatch: any) => ({
//    hideMenu: () => dispatch({ type: hideMenuType }),
//});

