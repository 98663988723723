import './Home.less';
import * as React from 'react';
import { connect, createSelectorHook } from 'react-redux';
import { Alert, Button, Card, Col, Collapse, Divider, Input, List, Pagination, Row, Select, Space, Spin, Switch, Table, Typography } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { debounce } from '../utils/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SimpleSelector from './filters/SimpleSelector';
import CategorieSelector from './filters/CategorieSelector';
import CategorieInEvidenzaSelector from './filters/CategorieInEvidenzaSelector';
import ListaArticoli from './ListaArticoli';
import { StatoUtenteEnum, typeViewArticoli } from '../utils/constants';
import { jsonDataFetch, JsonDataFetchErrorResult } from '../Zerouno/jsonDataFetch';
import { ApplicationState } from '../store';
import { Link } from 'react-router-dom';


const { Panel } = Collapse;
const { Search } = Input;
const { Text, Title } = Typography;


function Catalogo(props: any) {

    var [loading, setLoading] = useState(false);
    var [search, setSearch] = useState('');
    var [typeView, setTypeView] = useState(typeViewArticoli.Griglia);
    var [items, setItems] = React.useState([] as any[]);
    var [itemsNumber, setItemsNumber] = React.useState(0);
    var [currentPagenNumber, setcurrentPagenNumber] = React.useState(1);
    var [famigliaIds, setFamigliaIds] = React.useState([] as any[]);
    var [classeIds, setClasseIds] = React.useState([] as any[]);
    var [naturaIds, setNaturaIds] = React.useState([] as any[]);
    var [marcaIds, setMarcaIds] = React.useState([] as any[]);
    var [CatMercIds, setCatMercIds] = React.useState([] as any[]);
    var [sottocategorieIds, setSottocategorieIds] = React.useState([] as any[]);
    var [inevidenzaIds, setInevidenzaIds] = React.useState([] as any[]);

    var [customData, setCustomData] = React.useState({ famiglie: [], classi: [], catmerceologiche: [], nature: [], marche: [], sottocategorie: [], inevidenza: [] });

    // function handleSearch(event: any) {
    //   setSearch(event.target.value);
    // }

    function handleCustomData(customData: any) {
        setCustomData(customData);
        setcurrentPagenNumber(1);
    }

    function onChangePageNumber(page: any) {
        setcurrentPagenNumber(page);
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        setSearch = debounce(setSearch, 800);
    });

    useEffect(() => {
        const fetchOpts = {
            body: JSON.stringify({ take: 20, skip: (20 * (currentPagenNumber - 1)), orderby: "", filter: search, customData: { ...customData } })
        };

        setLoading(true);
        jsonDataFetch(`api/Query/articoli`, fetchOpts)
            .then(x => {
                if (x.data) {
                    let data = x.data;
                    setItems(data?.rows || []);
                    setItemsNumber(data?.totalRows || 0);
                    setCatMercIds(data?.aggregates?.catmerceologica)
                    setClasseIds(data?.aggregates?.classe)
                    setFamigliaIds(data?.aggregates?.famiglia)
                    setMarcaIds(data?.aggregates?.marca)
                    setNaturaIds(data?.aggregates?.natura)
                    setSottocategorieIds(data?.aggregates?.sottocategoria)
                    setInevidenzaIds(data?.aggregates?.inevidenza)
                }
            })
            .catch((x: JsonDataFetchErrorResult<{ message: string }>) => {
                if (x.data && x.data.message) {
                    //TODO: Come gestiamo l'intl in questo progetto?
                    //message.error(this.props.intl.formatMessage({ id: x.data.message }));)
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }, [search, customData, currentPagenNumber]);

    return (
        <Spin spinning={loading}>
            {props.user.statoUtente == StatoUtenteEnum.AttivoInAttesaDati &&
                <Alert
                    style={{ marginBottom: 8 }}
                    message={props.intl.formatMessage({ id: "global.msgCompletaDati" }, { link: <Link to="/UserCompletaDatiUtente">qui</Link> })}
                    type="info"
                    showIcon />
            }
            <Card style={{ marginBottom: 16 }} size='small'>
                <Row justify='center'>
                    <Space size="middle">
                        <Search style={{ width: 600 }} placeholder={props.intl.formatMessage({ id: "global.cerca" })} size='large' enterButton allowClear onChange={(event: any) => setSearch(event.target.value)} ></Search>
                    </Space>

                </Row>
            </Card>

            <Row gutter={16}>
                <Col md={5}>
                    <Card bodyStyle={{ margin: 0, padding: 0 }}>
                        <Collapse defaultActiveKey={['0', '1']} >
                            {inevidenzaIds?.length > 0 &&
                                <Panel header={<strong><FormattedMessage id="global.inevidenza" /></strong>} key="0">
                                    <CategorieInEvidenzaSelector indexName="inevidenza" ids={inevidenzaIds} setCustomData={handleCustomData} customData={customData} />
                                </Panel>
                            }
                            <Panel header={<span><FormattedMessage id="global.categoria" /></span>} key="1">
                                <CategorieSelector indexName="sottocategorie" ids={sottocategorieIds} setCustomData={handleCustomData} customData={customData} />
                            </Panel>
                            <Panel header={<span><FormattedMessage id="global.famiglia" /></span>} key="2">
                                <SimpleSelector indexName="famiglie" ids={famigliaIds} filter={search} setCustomData={handleCustomData} customData={customData}></SimpleSelector>
                            </Panel>
                            <Panel header={<span><FormattedMessage id="global.classe" /></span>} key="3">
                                <SimpleSelector indexName="classi" ids={classeIds} filter={search} setCustomData={handleCustomData} customData={customData}></SimpleSelector>
                            </Panel>
                            <Panel header={<span><FormattedMessage id="global.catmerceologica" /></span>} key="6">
                                <SimpleSelector indexName="catmerceologiche" ids={CatMercIds} filter={search} setCustomData={handleCustomData} customData={customData}></SimpleSelector>
                            </Panel>
                            <Panel header={<span><FormattedMessage id="global.natura" /></span>} key="4">
                                <SimpleSelector indexName="nature" ids={naturaIds} filter={search} setCustomData={handleCustomData} customData={customData}></SimpleSelector>
                            </Panel>
                            <Panel header={<span><FormattedMessage id="global.marca" /></span>} key="5">
                                <SimpleSelector indexName="marche" ids={marcaIds} filter={search} setCustomData={handleCustomData} customData={customData}></SimpleSelector>
                            </Panel>


                        </Collapse>

                    </Card>

                </Col>

                <Col md={19}>
                    <Card
                        title={search && (props.intl.formatMessage({ id: "global.articoliTrovati" }) + " \"" + search + "\"")}
                        extra={
                            <Space size='small'>
                                <Button onClick={() => setTypeView(typeViewArticoli.Lista)} icon={<UnorderedListOutlined />} type={typeView == typeViewArticoli.Lista ? 'primary' : 'text'} />
                                <Button onClick={() => setTypeView(typeViewArticoli.Griglia)} icon={<AppstoreOutlined />} type={typeView == typeViewArticoli.Griglia ? 'primary' : 'text'} />
                            </Space>}
                    >
                        <ListaArticoli items={items} typeView={typeView}></ListaArticoli>
                        <Row justify='center' style={{ marginTop: 16 }}>
                            <Pagination current={currentPagenNumber} onChange={onChangePageNumber} total={itemsNumber} showSizeChanger={false} defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} di ${total}`} />
                        </Row>
                    </Card>
                </Col>

            </Row>

        </Spin>
    );
}

export default injectIntl(
    connect((state: ApplicationState) => { return { ...state.layout, ...state.account, ...state.carrello } }
    )(Catalogo)
)
