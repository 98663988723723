import React from 'react';
import { Button, Input, Row, Space } from 'antd';
import { FilterValue, msg_addItem, msg_confirm, msg_reset, msg_search, getFilterValue, operators, dataType } from './helpers';
import Operators from './Operators';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CloseCircleFilled  } from '@ant-design/icons';
import { SearchOutlined, PlusOutlined  } from '@ant-design/icons';


export interface StringSearchProps {
    filterDropdownProps: FilterDropdownProps,
    dataIndex: string,
    dataType: dataType,
    title: string,
    showOperators: boolean,
}

export class StringSearch extends React.Component<StringSearchProps> {
    static defaultProps = {
        showOperators: true,
    };

    public get emptyFilterItem() : FilterValue {
        return { operator: this.props.dataType == "number" ? operators.eq.key : operators.contains.key, value: "" };
    }
    
    searchInput: any;

    getFilterValuesFromKeys = (keys: React.ReactText[] | undefined) : FilterValue[] => {
        if (!keys || keys.length == 0) return [];
        return keys.map(x => {
            return getFilterValue(x, operators.contains.key);
        });
    };

    addSearchItem = () => {
        const { setSelectedKeys, selectedKeys,  } = this.props.filterDropdownProps;
        let filters = this.getFilterValuesFromKeys(selectedKeys);
        filters.push(this.emptyFilterItem);
        // Alla creazione il primo filtro non esiste, quindi ne aggiungo due
        if (filters.length === 1) filters.push(this.emptyFilterItem); 
        setSelectedKeys && setSelectedKeys(filters.map(x => JSON.stringify(x)));
        setTimeout(() => this.searchInput.focus());
    };

    removeSearchItem = (index: any) => {
        const { setSelectedKeys, selectedKeys } = this.props.filterDropdownProps;
        let filters = this.getFilterValuesFromKeys(selectedKeys);
        filters = filters.filter((x: any, i: number) => i !== index);
        setSelectedKeys && setSelectedKeys(filters.map(x => JSON.stringify(x)));
    };

    confirm = () => {
        const { confirm, setSelectedKeys, selectedKeys } = this.props.filterDropdownProps;
        // Rimuove filtri vuoti
        const filters = this.getFilterValuesFromKeys(selectedKeys).filter((x: FilterValue) => x.value ? true : false);
        setSelectedKeys && setSelectedKeys(filters.map(x => JSON.stringify(x)));
        confirm && confirm();
    };
    
    reset = () => {
        const { clearFilters, confirm } = this.props.filterDropdownProps;
        clearFilters && clearFilters();
        confirm && confirm();
    }

    render() {
        const { selectedKeys, setSelectedKeys, clearFilters } = this.props.filterDropdownProps;
        const { title, dataType } = this.props;
        const operatorComboType = dataType == "number" ? "number" : "string";
        
        let inputs = this.getFilterValuesFromKeys(selectedKeys);
        if (inputs.length == 0) {
            inputs = [this.emptyFilterItem];
        }

        const canReset = JSON.stringify(inputs) != JSON.stringify([this.emptyFilterItem]);

        return (<>
            <div style={{ padding: 8, paddingBottom: 0 }}>
                {inputs.map((v: any, i: number) => 
                    <Row key={`search${i}`} justify="space-between">
                        <Input
                            addonBefore={this.props.showOperators &&
                                <Operators type={operatorComboType} value={inputs[i].operator} onChange={e => {
                                    inputs[i] = { ...v, operator: e };
                                    setSelectedKeys && setSelectedKeys(inputs.map(x => JSON.stringify(x)));
                                }} /> 
                            }
                            suffix={inputs.length > 1 &&
                                <CloseCircleFilled
                                    onClick={() => this.removeSearchItem(i)} 
                                    style={{ color: "rgba(0, 0, 0, .25)", fontSize: 12 }} 
                                />
                            }
                            ref={node => { this.searchInput = node }}
                            placeholder={`${msg_search} ${title}`}
                            value={inputs[i].value}
                            onChange={e => {
                                    inputs[i] = { ...v, value: e.target.value };
                                    setSelectedKeys && setSelectedKeys(inputs.map(x => JSON.stringify(x)));
                                }
                            }
                            onPressEnter={this.confirm}
                            style={{ width: "100%", marginBottom: 8, display: 'block' }}
                            />
                    </Row>)
                }
            </div>

            <Row justify="space-between" style={{borderTop: "1px solid #f0f0f0", padding: 8}}>
                <Button type="dashed" size="small" style={{ width: 40 }} onClick={this.addSearchItem} title={msg_addItem} icon={<PlusOutlined />} />
                <Space>
                    <Button type="link" onClick={this.reset} disabled={!canReset} size="small" style={{ }}>{msg_reset}</Button>
                    <Button type="primary" onClick={this.confirm} size="small" style={{ }}>{msg_confirm}</Button>
                </Space>
            </Row>
            
            {/* <Space>
                <Button type="dashed" size="small" style={{ width: 40 }} onClick={this.addSearchItem} title={msg_addItem} icon={<PlusOutlined />} />

                <Button type="default" onClick={() => { clearFilters && clearFilters(); }} size="small" style={{ width: 90 }}>
                    {msg_reset}
                </Button>

                <Button type="primary" onClick={this.confirm} size="small" style={{ width: 90 }} icon={<SearchOutlined />}>
                    {msg_confirm}
                </Button>
            </Space> */}
        </>);
    }
}
