import { Alert, Button, Checkbox, Form, Input, Card, Row, Divider, Space } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as AccountStore from '../../store/accountStore';
import * as CarrelloStore from '../../store/carrelloStore';
import * as CataloghiStore from '../../store/cataloghiStore';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import './Login.less'

const msg_welcome = "Benvenuto";
const msg_loginFailedDescription = "La combinazione di username e password non è stata riconosciuta";
const msg_loginFailed = "Login fallita";
const msg_username = "Username";
const msg_password = "Password";
const msg_usernameError = "Inserire username";
const msg_passwordError = "Inserire password";
const msg_rememberMe = "Ricordami";
const msg_passwordForgotten = "Password dimenticata?";
const msg_registration = "Crea nuovo account";

class LoginComponent extends React.Component<any, any> {

    state = {
        succeded: null,
        error: null,
        loading: false,
    }



    handleSubmit = (values: any) => {
        this.setState({ loading: true, error: null });

        const minimumDelay = (ms: number) => new Promise(res => setTimeout(res, ms));

        const successDelay = minimumDelay(500);
        const errorDelay = minimumDelay(3000);

        this.fetchLogin(values).then(
            async (succeded) => {

                if (succeded) {
                    await successDelay;
                    this.setState({ loading: false, succeded: true, error: null });
                    this.checkIdentity();
                    var { carrello, cataloghi } = this.props;
                    // this.setState({ articoli: carrello });        
                    var res = await fetch("/api/Carrelli/GetCarrello");
                    var json = res.ok && await res.json();
                    if (json) {
                        if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                            //Risposta vuota no carrello
                            this.props.setCarrello([]);
                        } else {
                            carrello = json.articoliQta;
                            // descrizioneLinea = json.descrizione;
                            this.props.setCarrello(carrello);
                        }
                    }

                    var res = await fetch("/api/Cataloghi/GetCataloghi");
                    var json = res.ok && await res.json();
                    if (json) {
                        if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                            //Risposta vuota no carrello
                            this.props.setCataloghi([]);
                        } else {
                            cataloghi = json;
                            // descrizioneLinea = json.descrizione;
                            this.props.setCataloghi(cataloghi);
                        }
                    }

                    this.props.history.push("/");
                    return;
                }
                await errorDelay;
                this.setState({ loading: false, succeded: false, error: true });
            },
            async (error) => {
                await errorDelay;
                this.setState({ loading: false, succeded: false, error: true });
            }
        );
    };

    fetchLogin(values: any) {
        return fetch('api/account/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            body: JSON.stringify(values)
        }).then(res => res.json());
    }

    checkIdentity = () => {
        var options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        };

        fetch('api/account/getcurrentuser', options)
            .then(async (response: any) => {
                const json = response.ok && await response.json();
                this.props.setAccount(json);
            })
            .catch((reason: any) => {
                //console.log("getcurrentuser failed", reason);
                this.props.setAccount({ isAuthenticated: false });
            });
    }


    render() {
        const { succeded, error, loading } = this.state;


        return (
            <Row justify="center" align="middle" style={{ minHeight: "100%" }} className="login-container">

                <Card title={msg_welcome}
                    className="login-card"
                    bodyStyle={{ marginRight: 20, marginLeft: 20, marginTop: 8, marginBottom: 16 }}
                    headStyle={{ fontSize: 18 }}
                >
                    <Form layout="vertical" onFinish={this.handleSubmit} className="login-form" initialValues={{ RememberMe: false }}>

                        {error ? (<Alert description={msg_loginFailedDescription} message={msg_loginFailed} type="error" showIcon style={{ marginBottom: 16 }} />) : null}

                        <Form.Item label={msg_username} name="Username" rules={[{ required: true, message: msg_usernameError }]}>
                            <Input size="large" prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item label={msg_password} name="Password" rules={[{ required: true, message: msg_passwordError }]}>
                            <Input size="large" prefix={<LockOutlined />} type="password" />
                        </Form.Item>

                        <Form.Item style={{ marginTop: 32 }}>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                icon={<LoginOutlined />}
                                style={{ width: "100%" }}
                                loading={loading}
                            >Login</Button>
                        </Form.Item>

                        <Row justify="space-between" >
                            <Form.Item name="RememberMe" valuePropName="checked" noStyle>
                                <Checkbox>{msg_rememberMe}</Checkbox>
                            </Form.Item>
                            <Space size={'small'}>
                                <Link to="/UserPasswordDimenticata">{msg_passwordForgotten}</Link>
                                <Divider type='vertical' />
                                <Link to="/UserRegistrazione">{msg_registration}</Link>
                            </Space>
                        </Row>

                    </Form>
                </Card>

            </Row>
        );
    };
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(LoginComponent);