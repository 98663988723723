import * as React from 'react';
import { List, message, Tree, TreeSelect, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { jsonDataFetch, JsonDataFetchErrorResult } from '../../Zerouno/jsonDataFetch';
import { Checkbox } from 'antd';

const {Text} = Typography;

interface IProps {
  indexName: string;
  filter?: string;
  bordered?: boolean;
  ids?: any[];
  setCustomData: (customData: any) => void,
  customData?: any,
}

export default function SimpleSelector(props: IProps) {

    const [items, setItems] = useState([] as any[]);

    useEffect(() => {
      const fetchOpts = {
          body: JSON.stringify({ take: 200, skip: 0, orderby: "", /*filter: props.filter,*/ customData: { ids: props.ids?.map(x => x.key) } })
      };  

      jsonDataFetch(`api/Query/${props.indexName}`, fetchOpts)
        .then(x => {
            if (x.data) {
              let rows = x.data?.rows || [];
              let items = rows.map((i: any) => (
                {
                  key: i.id, 
                  value: i.id, 
                  title: i.descrizione, 
                }));
                setItems(items);
            }
        })
        .catch((x: JsonDataFetchErrorResult<{ message: string }>) => {
            if (x.data && x.data.message) {
                //TODO: Come gestiamo l'intl in questo progetto?
                //message.error(this.props.intl.formatMessage({ id: x.data.message }));)
            }
        })
        .finally(() => {
            
        });

    }, [props.ids, props.filter]);

    function setCheckedKeys (checkedKeys: any) {
      let { customData, indexName } = props;
      let newData = {...customData};
      newData[indexName] = checkedKeys;
      props.setCustomData(newData);
    }

    return (
        <>

          {/* { <List style={{ maxHeight: 102, overflow: "auto"}}
            size="small"
            bordered={props.bordered}
                dataSource={items}
                renderItem={item => <List.Item><Checkbox>{item.title} <Text type="secondary">({item.key})</Text></Checkbox></List.Item>}
          /> } */}

            
          <Tree style={{ maxHeight: 104, overflow: "auto", marginLeft: -16}}
            treeData={items}
            checkable
            onCheck={(checkedKeys: any)=>setCheckedKeys(checkedKeys)}
          />
           
      </>
    );
}

