import * as React from 'react';
import { Select, Spin, message, Col, Row } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

const { Option } = Select;

const msg_loadingError = "Caricamento dati non riuscito";
const msg_selectAnItem = "Seleziona un elemento";

/*
    versione : 22.05.16
    Changelog :
        22.05.16 Utilizzo InitialData come set di dati iniziale
        21.11.05 stripe ExcludeFilterOnIdLoookup + fix multiple
        21.11.02 ExcludeFilterOnIdLoookup
        21.03.04 CaseInsensitiveSearch
        20.09.30 bugfix additionalfilter su ricerca
        20.09.25 fix initialFilterById
        20.09.24 filterById e initialFilterById in multiple mode
        20.09.14 aggiunto renderItem
        20.09.11 applicati filtri base anche a filterById
        20.05.08 prof filter
        20.03.04 antd v4
    TODO : 
    - filtri per tipi dato diversi da stringa (ora solo "contains")
    - Gestire initialData anche componentDidUpdate?
    - Il dato letto da filterById dovrebbe finire in state.initialData o cmq essere cachato?
    - Letture pagine successive
    - Chiamata eventi originali delle pros onChange, onSearch, onDropdownVisibleChange?
*/

export interface ZuSelectProps<VT> extends SelectProps<VT> {
    initialData: any[];
    url: string;
    pageSize: number;
    searchColumns: string | string[] | null | undefined;
    sortColumns: string | string[] | null | undefined;
    valueColumn: string;
    textColumn: string;
    filter: string;
    transformData: (dataRow: any) => {};
    renderItem?: (item: any) => React.ReactNode;
    caseInsensitiveSearch?: boolean;
    excludeFilterOnIdLoookup?: boolean;
}

export default class ZuSelect<VT extends SelectValue = SelectValue> extends React.Component<ZuSelectProps<VT>, {}> {

    static defaultProps = {
        initialData: [],
        url: null,
        pageSize: 30,
        searchColumns: null,  //textColumn se null
        sortColumns: null,
        valueColumn: "value",
        textColumn: "text",
        filter: null,
        transformData: (dataRow: any) => dataRow,
    }

    state = {
        data: this.props.initialData,
        initialData: this.props.initialData,
        value: undefined,
        loading: false,
        pagination: { totalRows: 0, current: 1 },
    };

    componentDidMount() {
        //console.log("select did mount",this.props, this.state);
        this.handleSearch = debounce(this.handleSearch, 400);
        if (this.props.value) {
            this.initialFilterById();
        }
    }

    componentDidUpdate(propsPrecedenti: any, statePrecedenti: any) {
        //console.log("select did update",this.props, propsPrecedenti, this.state, statePrecedenti);
        // Carica il dato per id se non esiste tra quelli presenti
        if (this.props.value && this.props.value != propsPrecedenti.value) {
            this.initialFilterById();
        }
    }

    initialFilterById() {
        const { data } = this.state;
        const { value, valueColumn, mode } = this.props;
        const stateValues = this.state.value || [];

        let searchValueInStateData = (v: VT | undefined) => data.find((f: any) => f[valueColumn] == v);

        let exists: boolean | undefined = true;
        if (mode == "multiple") {
            if (Array.isArray(value) && value.length > 0) {
                var values = value as Array<any>;
                // esistono valori non presenti nello state ?
                // - quindi o situazione iniziale o valore settato dall'esterno
                // - se nello state allora li sta settando la select e va evitato il fetch
                var newValues = values.filter(v => !stateValues.find(x => x == v)).length > 0;
                if (newValues) {
                    // tutti i valori sono nello state (non esistono valori che non sono nello state)
                    exists = values.filter(v => !searchValueInStateData(v)).length == 0;
                }
            }
            else
                return;
        }
        else {
            // singolo : il valore è nello state
            exists = searchValueInStateData(value);
        }

        if (!exists) {
            this.filterById(value);
        }
    }

    filterString = async (filter: any) => {
        //TODO: utilizzare string | string[] per campi ************************************

        let params = {
            take: this.props.pageSize,
            skip: ((this.state.pagination.current || 1) - 1) * (this.props.pageSize || 0),
            orderby: `${this.getComlumnNames(this.props.sortColumns || this.props.textColumn)}`,
            filter: `${this.getComlumnFiltersString(this.props.searchColumns || this.props.textColumn, filter)}`,
            select: `new (${this.getComlumnNames(this.props.textColumn)}, ${this.props.valueColumn})`,
        }

        this.fetch(params);
    }

    filterById = async (id: any) => {
        var filterById = `${this.props.valueColumn} = "${id}"`;
        var take = 1;

        if (this.props.mode == "multiple") {
            if (Array.isArray(id) && id.length > 0) {
                filterById = id.map(x => `${this.props.valueColumn} = "${x}"`).join(' or ');
                take = id.length;
            }
            else
                return;
        }

        let filter = (this.props.filter && !this.props.excludeFilterOnIdLoookup)
            ? `(${this.props.filter}) and (${filterById})` : filterById;

        let params = {
            take: take,
            skip: 0,
            orderby: "",
            filter: filter,
            select: `new (${this.props.textColumn}, ${this.props.valueColumn})`,
        }

        this.fetch(params);
    }

    getComlumnNames(value: string | string[] | null | undefined) {
        if (Array.isArray(value)) {
            return value.join(",");
        }

        return value || "";
    }

    getComlumnFiltersString(value: string | string[] | null | undefined, filter?: string) {
        //console.log("getComlumnFiltersString", value, filter)

        let additionalFilter = this.props.filter || "";

        if (!filter) return additionalFilter;
        if (!value) return additionalFilter;

        if (this.props.caseInsensitiveSearch) {
            filter = filter.toUpperCase();
        }

        const f = `.Contains("${filter}")`;

        let res = "";
        if (Array.isArray(value)) {
            if (value.length == 0) return additionalFilter;

            if (this.props.caseInsensitiveSearch) {
                value = value.map((item: any) => { return `${item}.ToUpper()` });
            }

            res = value.join(`${f} or `).concat(f);
        } else {
            if (this.props.caseInsensitiveSearch) {
                value = `${value}.ToUpper()`;
            }

            res = value.concat(f);
        }

        return additionalFilter ? `(${additionalFilter}) and (${res})` : res;
    }

    fetch = async (params: any) => {
        //console.log("fetch", params, this.props, this.state);
        this.setState({ data: [], loading: true });

        const url = this.props.url;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...params
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            data = await (res.ok && res.json()) || {};
            let transformedData = data.rows.map(this.props.transformData);
            const pagination = { totalRows: data.totalRows };
            this.setState({ loading: false, data: transformedData, pagination });
        }
        else {
            message.error(msg_loadingError);
            this.setState({ loading: false });
        }
    }

    handleSearch = (search: string) => {
        this.filterString(search);
        'function' === typeof this.props.onSearch && this.props.onSearch.apply(this, [search]);
    }

    handleChange = (value: any, option: any) => {
        this.setState({
            value,
            loading: false,
        });
        'function' === typeof this.props.onChange && this.props.onChange.apply(this, [value, option]);
    };

    // Caricamento valori senza filtro alla open della select
    handleDropdownVisibleChange = (open: boolean) => {
        if (open) {
            this.filterString("");
        }
        'function' === typeof this.props.onDropdownVisibleChange && this.props.onDropdownVisibleChange.apply(this, [open]);
    }

    renderItem = (item: any) => {
        const { valueColumn, textColumn, renderItem } = this.props;
        return renderItem != undefined ? renderItem(item) : item[textColumn];
    }

    render() {
        const { loading, data, value } = this.state;

        const {
            // https://stackoverflow.com/questions/45598659/extending-component-event-handler-props-in-react
            onChange, onSearch, onDropdownVisibleChange,

            // Strip delle props specifiche di ZuSelect (per evitare React does not recognize the `xxx` prop on a DOM element)
            initialData, url, pageSize, searchColumns, sortColumns, valueColumn, textColumn, filter, transformData, renderItem, caseInsensitiveSearch, excludeFilterOnIdLoookup,

            // Il resto passato 
            ...props } = this.props;

        return (
            <Select
                showSearch
                allowClear
                value={value}
                placeholder={msg_selectAnItem}
                notFoundContent={loading ? <Spin size="small" /> : null}
                filterOption={false}
                style={{ width: '100%' }}

                onSearch={this.handleSearch}
                onChange={this.handleChange}
                onDropdownVisibleChange={this.handleDropdownVisibleChange}

                {...props}
            >
                {data.map((item: any) => (
                    <Option key={item[valueColumn]} value={item[valueColumn]}>
                        {
                            this.renderItem(item)
                        }
                    </Option>
                ))}
            </Select >

        );
    }
}

function debounce<T extends Function>(cb: T, wait = 20) {
    var h: any;
    let callable: any = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => cb(...args), wait);
    };
    return callable as T;
};


