import React from 'react';
import { StringSearch } from './StringSearch';
import { DateSearch } from './DateSearch';
import { dataType } from './helpers';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined  } from '@ant-design/icons';

export class SearchDropdown {

    dropDown: any;

    getColumnSearchProps = (dataIndex: string, title: string, dataType: dataType) => {

        return ({
            filterDropdown: (filterDropdownProps: FilterDropdownProps) => {
                //console.log("filterDropdown");

                if (dataType?.toLowerCase() === "date") {
                    return (<DateSearch 
                        filterDropdownProps={filterDropdownProps}
                        title={title}
                        dataIndex={dataIndex}
                    />);
                }
                return (<StringSearch 
                        filterDropdownProps={filterDropdownProps}
                        title={title}
                        dataIndex={dataIndex}
                        dataType={dataType}
                        ref={node => this.dropDown = node }
                    />);
            },
            //filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => this.dropDown?.searchInput?.focus());
                }
            },

        })
    };
}
