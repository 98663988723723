import * as React from 'react';
import { connect } from 'react-redux';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore';
import * as CataloghiStore from '../store/cataloghiStore';
import { ApplicationState } from '../store';
import { Avatar, Button, Card, Dropdown, Form, Row, Space, Tooltip, Menu } from 'antd';
import { UserOutlined, BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';

const { Meta } = Card;

class ZuAccountHelper extends React.Component<any, {}> {

    static defaultProps = {
        checkIdentityOnMount: true,
        checkIdentityInterval: 60,
    }

    timer?: any;

    componentDidMount() {
        //console.info("componentDidMount");
        if (this.props.checkIdentityOnMount) {
            this.checkIdentity();
        }

        if (this.props.checkIdentityInterval && (this.props.checkIdentityInterval > 0)) {
            this.timer = setInterval(this.checkIdentity, this.props.checkIdentityInterval * 1000);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
    }

    checkIdentity = () => {
        var options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        };

        fetch('api/account/getcurrentuser', options)
            .then(async (response: any) => {
                const json = response.ok && await response.json();
                //console.log("auth", json)
                this.props.setAccount(json);
            })
            .catch((reason: any) => {
                //console.log("getcurrentuser failed", reason);
                this.props.setAccount({ isAuthenticated: false });
            });
    }

    handleLogout = () => {
        fetch('api/account/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(
                (succeded) => {
                    if (succeded) {
                        this.props.clearAccount();
                        this.props.clearCarrello();
                        this.props.clearCataloghi();
                        this.props.history.push("/");
                        return;
                    }

                    console.info("logout failed");
                },
                (error) => {
                    console.error(error);
                }
            )
    }

    extractNameFromEmail = (text: string)  => {
        let i = text.indexOf("@");
        if (i < 0) {
            return text;
        }
        return text.substring(0, i);
    }

    getInitials = (text: string) => {
        text = this.extractNameFromEmail(text || "");
        var initials = text.replace(/[^a-zA-Z- +_\.]/g, "").match(/\b\w/g);
        return (initials || []).slice(0, 2).join('').toUpperCase();
    };

    render() {
        const { user } = this.props;
        const userPrimaryInfo = user.nomeCognome || user.name || user.username;
        const userSecondaryInfo = user.email || user.name || user.username;
        const initials = this.getInitials(userPrimaryInfo);
        const userOverlay = 

                <Menu style={{width: 300, boxShadow: "0 2px 10px rgb(0 0 0 / 20%)"}}>
                    <Space style={{ margin: 16 }}>
                        <Avatar size="large">{initials}</Avatar>
                        <div><strong>{userPrimaryInfo}</strong><br />{userSecondaryInfo}</div>
                    </Space>
                    {/* <Menu.Item style={{marginTop: 8, marginBottom: 8}}  >
                        <Space >
                            <Avatar size="large">{initials}</Avatar>
                            <div><strong>{username}</strong><br />{user.email}</div>
                        </Space>
                    </Menu.Item> */}
                    <Menu.Divider />
                    <Menu.Item>
                        <Link to={"/UserCambioPassword"}>Cambio password</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <a onClick={this.handleLogout}>Esci</a>
                    </Menu.Item>
                </Menu>
                ;

        if (user && user.isAuthenticated) {
            return (
                <Dropdown placement="bottomRight" trigger={['click']} overlay={userOverlay}>
                    <Avatar style={{color: ""}}><span style={{cursor: "pointer"}}>{initials}</span></Avatar>
                </Dropdown>
            );

                {/*<Button type="ghost" shape="round" htmlType="submit" */}
                {/*    style={{padding: "0px 8px 0px 4px", backgroundColor: "#f2f2f2"}}*/}
                {/*    icon={<Button type="primary" size="small" shape="circle" icon={<UserOutlined />} />}>*/}
                {/*    &nbsp;{user.name || user.username}*/}
                {/*    </Button >*/}

        }

        return (
            <Button type="link" icon={<UserOutlined />}>
                <Link to="/login">&nbsp;Accedi</Link>
            </Button >

            // <Link to="/login">
            //     <UserOutlined /> Accedi
            // </Link>
        );
    }
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CarrelloStore.actionCreators, ...CataloghiStore.actionCreators }
)(withRouter(ZuAccountHelper));