import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CarrelloStore from '../store/carrelloStore'
import * as CataloghiStore from '../store/cataloghiStore';
import { FormInstance } from "antd/lib/form";
import { Card, Col, Row, Space, Typography, Image, Divider, Select, Form, Button, Modal, Input, List, message, Popconfirm, notification } from 'antd';
import { EditOutlined, DeleteOutlined, UnorderedListOutlined, AppstoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { msg_requiredField, typeViewArticoli } from '../utils/constants';
import ZuFormDetail from '../Zerouno/ZuFormDetail';
import noImg from './noImg.png';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const BASEPATHIMMAGINI = "StaticFiles";
const { Meta } = Card;


class CataloghiDetailComponent extends ZuFormDetail {
    formRef = React.createRef<FormInstance>();

    static defaultProps = {
        catalogo: {}
    }

    state = {
        rinominaCatalogo: false,
        loadingBtn: false,
        visiblePop: false,
        loadingSingleBtn: "",
        visibleSinglePop: "",
        loadingSingleBtnRemove: "",
        typeView: typeViewArticoli.Griglia,
    }

    componentDidMount() {

    }

    aggiornaCataloghi = async () => {
        var res = await fetch("/api/Cataloghi/GetCataloghi");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCataloghi([]);
                this.setState({ rinominaCatalogo: false, loadingBtn: false, visiblePop: false, loadingSingleBtn: "", visibleSinglePop: "", loadingSingleBtnRemove: "" });
                return;
            } else {
                let cataloghi = json;
                // descrizioneLinea = json.descrizione;
                this.props.setCataloghi(cataloghi);
                this.setState({ rinominaCatalogo: false, loadingBtn: false, visiblePop: false, loadingSingleBtn: "", visibleSinglePop: "", loadingSingleBtnRemove: "" });
                return;
            }
        }
    }

    rinominaCatalogo = async (values: any) => {
        this.setState({ loadingBtn: true });
        let model = { id: this.props.catalogo.id, descrizione: values.nomeCatalogo };

        const response = await fetch("api/Cataloghi/RenameCatalogo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            //this.setState({ loading: false });
        }
    }

    eliminaCatalogo = async () => {
        this.setState({ loadingBtn: true });
        let model = { id: this.props.catalogo.id };

        const response = await fetch("api/Cataloghi/DeleteCatalogo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            //this.setState({ loading: false });
        }
    }

    rimuoviArticolo = async (idArticolo: any) => {
        this.setState({ loadingSingleBtnRemove: idArticolo });
        let model = { idCatalogo: this.props.catalogo.id, idArticolo: idArticolo };

        const response = await fetch("api/Cataloghi/RemoveArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingSingleBtnRemove: "" });
        }
    }

    aggiornaCarrello = async () => {
        var res = await fetch("/api/Carrelli/GetCarrello");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCarrello([]);
                this.setState({ loadingSingleBtn: "" });
                return;
            } else {
                let carrello = json.articoliQta;
                // descrizioneLinea = json.descrizione;
                this.props.setCarrello(carrello);
                this.setState({ loadingSingleBtn: "" });
                notification.success({ message: 'Articolo aggiunto al carrello' })
                return;
            }
        }

    }

    aggiungiArticoloAlCarrello = async (idArticolo: any) => {
        this.setState({ loadingSingleBtn: idArticolo });

        let model = { idArticolo: idArticolo, quantita: 1 };

        const response = await fetch("api/Carrelli/AddArticolo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCarrello, 1000);
        }
        else {
            message.error("Errore");
            this.setState({ loadingSingleBtn: "" });
        }
    }


    render() {
        const { rinominaCatalogo, loadingBtn, visiblePop, loadingSingleBtn, visibleSinglePop, loadingSingleBtnRemove, typeView } = this.state;
        const { catalogo } = this.props;
        const articoliLista = catalogo?.articoli;
        return (
            <>
                <Row justify='space-between'>
                    <Space>
                        <Title level={4}>{catalogo.descrizione}</Title>
                    </Space>
                    <Space align='end' className="noprint">
                        <Space style={{ marginBottom: 10 }} >
                            <Button type='link' onClick={() => {
                                let t = document.title;
                                document.title = catalogo.descrizione;
                                window.print();
                                document.title = t;
                            }} >Stampa/Esporta <PrinterOutlined /></Button>
                            <Divider type="vertical"></Divider>
                            <Button type='link' onClick={() => this.setState({ rinominaCatalogo: true })}>Rinomina <EditOutlined /></Button>
                            <Divider type="vertical"></Divider>
                            <Popconfirm title="Eliminare il catalogo?" onConfirm={this.eliminaCatalogo} onCancel={() => this.setState({ visiblePop: false })} okButtonProps={{ loading: loadingBtn }} visible={visiblePop}>
                                <Button type='link' onClick={() => this.setState({ visiblePop: true })}>Elimina <DeleteOutlined /></Button>
                            </Popconfirm>
                        </Space>
                    </Space>
                </Row>
                <Divider style={{ marginTop: 0 }} className="noprint" />
                <Row justify='space-between'>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space align='end' className="noprint">
                        <Space >
                            <Button onClick={() => this.setState({ typeView: typeViewArticoli.Lista })} icon={<UnorderedListOutlined />} type={typeView == typeViewArticoli.Lista ? 'primary' : 'text'} />
                            <Button onClick={() => this.setState({ typeView: typeViewArticoli.Griglia })} icon={<AppstoreOutlined />} type={typeView == typeViewArticoli.Griglia ? 'primary' : 'text'} />
                        </Space>
                    </Space>
                </Row>
                {articoliLista?.length > 0 ?
                    (typeView == typeViewArticoli.Lista ?
                        (<List className="printList">
                            {(articoliLista || []).map((articolo: any) =>
                                <List.Item key={articolo.id} className="printList">
                                    <Col md={4} >
                                        <Image
                                            className="printImage"                                            
                                            preview={false}
                                            width={'100%'}
                                            src={articolo?.immaginedefault ? articolo?.immaginedefault : (articolo?.immagini?.length > 0 ? `${BASEPATHIMMAGINI}/${articolo?.immagini[0]}` : noImg)}
                                            style={{ aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center center' }}
                                        />
                                    </Col>
                                    <Col md={14} offset={1}>
                                        <List.Item.Meta
                                            title={<Link to={`/ArticoliDetail/${articolo.idArticolo}`}>{articolo.idArticolo}</Link>}
                                            description={articolo.descrizione}
                                        />
                                    </Col>
                                    <Col md={5} className="noprint">
                                        <Space direction='vertical' align='center'>
                                            {/* <Button
                                                type='primary'
                                                onClick={() => this.aggiungiArticoloAlCarrello(articolo.idArticolo)}
                                                loading={loadingSingleBtn === articolo.idArticolo}
                                            >
                                                Aggiungi al carrello
                                            </Button> */}
                                            <Popconfirm
                                                title="Rimuovera l'articolo dal catalogo?"
                                                onConfirm={() => this.rimuoviArticolo(articolo.idArticolo)}
                                                onCancel={() => this.setState({ visibleSinglePop: "" })}
                                                okButtonProps={{ loading: loadingSingleBtnRemove === articolo.idArticolo }}
                                                visible={visibleSinglePop === articolo.idArticolo}>
                                                <Button type='link' onClick={() => this.setState({ visibleSinglePop: articolo.idArticolo })}>Rimuovi <DeleteOutlined /></Button>
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </List.Item>
                            )}
                        </List>)
                        :
                        (<Row>
                            {(articoliLista || []).map((articolo: any) =>
                                <Col md={6} key={articolo.idArticolo} style={{ padding: 8 }}>                                    
                                    <Card
                                        className='printCardArticolo'
                                        size="small"
                                        //extra={<Button type="text" size="small"><EllipsisOutlined /></Button>}
                                        style={{ width: '100%' }}
                                        hoverable
                                        cover={
                                            <Link to={`/ArticoliDetail/${articolo.idArticolo}`}>
                                            <Image
                                                className="printImage"                                                
                                                preview={false}
                                                width={'100%'}
                                                src={articolo?.immaginedefault ? articolo?.immaginedefault : (articolo?.immagini?.length > 0 ? `${BASEPATHIMMAGINI}/${articolo?.immagini[0]}` : noImg)}
                                                // {...((articolo?.immagini?.length === 0 || !articolo?.immagini) && !articolo?.immaginedefault) && 
                                                //     { fallback: "data:/mg.png;"}}
                                                style={{ aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center center' }}
                                            />
                                            </Link>
                                        }
                                        actions={[
                                            <Row style={{ margin: '-12px 0px' }}>
                                                {/* <Col span={24} className="noprint">
                                                    <Button
                                                        block
                                                        type='primary'
                                                        onClick={() => this.aggiungiArticoloAlCarrello(articolo.idArticolo)}
                                                        loading={loadingSingleBtn === articolo.idArticolo}
                                                    >
                                                        Aggiungi al carrello
                                                    </Button>
                                                </Col> */}
                                                <Col span={24} className="noprint">
                                                    <Popconfirm
                                                        title="Rimuovera l'articolo dal catalogo?"
                                                        onConfirm={() => this.rimuoviArticolo(articolo.idArticolo)}
                                                        onCancel={() => this.setState({ visibleSinglePop: "" })}
                                                        okButtonProps={{ loading: loadingSingleBtnRemove === articolo.idArticolo }}
                                                        visible={visibleSinglePop === articolo.idArticolo}>
                                                        <Button type='link' onClick={() => this.setState({ visibleSinglePop: articolo.idArticolo })}>Rimuovi <DeleteOutlined /></Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        ]}
                                    >
                                        <Link to={`/ArticoliDetail/${articolo.idArticolo}`}><Meta description={<><span style={{ color: 'black' }}>{articolo.idArticolo}</span><br />{articolo.descrizione}</>} /></Link>
                                    </Card>
                                </Col>
                            )}
                        </Row>)
                    )
                    :
                    <List dataSource={[]} renderItem={item => <List.Item>{item}</List.Item>} />
                }

                {rinominaCatalogo &&
                    <Modal
                        visible={rinominaCatalogo}
                        title="Rinomina catalogo"
                        onOk={() => this.formRef.current?.submit()}
                        onCancel={() => this.setState({ rinominaCatalogo: false })}
                        maskClosable={false}
                        forceRender={true} // necessario !!!
                        width={800}
                        okButtonProps={{ loading: loadingBtn }}
                    >
                        <Form ref={this.formRef} layout="vertical" onFinish={this.rinominaCatalogo} initialValues={{ nomeCatalogo: catalogo.descrizione }} >
                            <Row gutter={16}>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Nome catalogo" name="nomeCatalogo" rules={[{ required: true, message: msg_requiredField }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                }
            </>
        );
    };
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CataloghiStore.actionCreators, ...CarrelloStore.actionCreators }
)(CataloghiDetailComponent);