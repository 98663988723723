import React from 'react';
import { Button, Radio, DatePicker, Row } from 'antd';
import { FilterValue, operators, operatorType, msg_reset, msg_confirm } from './helpers';
import moment from 'moment'
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined  } from '@ant-design/icons';

const { MonthPicker } = DatePicker;

export interface DateSearchProps {
    filterDropdownProps: FilterDropdownProps,
    dataIndex: string,
    title: string,
}

const radioStyle = {
     display: 'block',
};

const pickerStyle = {
    width: 180,
    marginLeft: 24,
};

export class DateSearch extends React.Component<DateSearchProps> {

    state = {
        value: undefined as operatorType | undefined,
        month: moment().startOf("month"),
        startDate: moment().subtract(1, "month"),
        endDate: moment(),
    }

    getFilterValuesFromKeys = (keys: React.ReactText[] | undefined) => {
        if (!keys || keys.length == 0) return [];
        return (keys).map(x => JSON.parse(x.toString()));
    };

    computeFilterValue(): FilterValue | undefined {
        if (!this.state.value) return;

        let f = { operator: this.state.value, value: moment().format("L") } as FilterValue;

        if (f.operator === operators.month.key) {
            f.value = this.state.month?.format("L");
        }

        if (f.operator === operators.range.key) {
            f.value = this.state.startDate?.format("L");
            f.value2 = this.state.endDate?.format("L");
        }

        return f;
    }

    confirm = () => {
        const { confirm, setSelectedKeys } = this.props.filterDropdownProps;

        let filter = this.computeFilterValue();
        if (!filter) {
            setSelectedKeys && setSelectedKeys([]);
        } else {
            setSelectedKeys && setSelectedKeys([JSON.stringify(filter)]);
        }

        confirm && confirm();
    };

    reset = () => {
        this.setState({ value: undefined });
        const { clearFilters, confirm } = this.props.filterDropdownProps;
        clearFilters && clearFilters();
        confirm && confirm();
    }

    radioChange = (value: operatorType) => {
        this.setState({ value: value }, () => {
            let dontConfirm = [operators.month.key, operators.range.key];
            if (!dontConfirm.find(x => x === value))
            {
                this.confirm();
            }
        });
    }

    render() {
        const { selectedKeys, clearFilters } = this.props.filterDropdownProps;
        const { value } = this.state;
        const canReset = value != null;

        return (<>
            <div style={{ padding: 12, paddingTop: 8 }}>
                <Radio.Group onChange={(e: any) => this.radioChange(e.target.value)} value={value}>
                    <Radio value={operators.today.key} style={radioStyle}>{operators.today.label}</Radio>
                    <Radio value={operators.lastWeek.key} style={radioStyle}>{operators.lastWeek.label}</Radio>
                    <Radio value={operators.yearToDate.key} style={radioStyle}>{operators.yearToDate.label}</Radio>
                    <Radio value={operators.month.key} style={radioStyle}>{operators.month.label}</Radio>
                                <MonthPicker
                                    allowClear={false}
                                    style={{...pickerStyle}}
                                    format={'MMMM YYYY'}
                                    value={this.state.month}
                                    onChange={(e: any) => this.setState({ month: e })}
                                    onFocus={() => {
                                        this.radioChange(operators.month.key);
                                    }}

                                />
                    <Radio value={operators.range.key} style={radioStyle}>
                            {operators.range.label}
                    </Radio>

                    <Row style={{ marginBottom: 4 }}>
                        <DatePicker
                            style={{...pickerStyle}}
                            format={moment.localeData().longDateFormat('L')}
                            value={this.state.startDate}
                            onChange={(e: any) => {
                                this.setState({ startDate: e });
                            }}
                            onFocus={(e: any) => {
                                this.radioChange(operators.range.key);
                            }}
                        />
                    </Row>
                    <Row>
                        <DatePicker
                            style={{...pickerStyle}}
                            format={moment.localeData().longDateFormat('L')}
                            value={this.state.endDate}
                            onChange={(e: any) => {
                                this.setState({ endDate: e });
                            }}
                            onFocus={(e: any) => {
                                this.radioChange(operators.range.key);
                            }}
                        />
                    </Row>

                </Radio.Group>
            </div>

            <Row justify="space-between" style={{borderTop: "1px solid #f0f0f0", padding: 8}}>
                <Button type="link" onClick={this.reset} disabled={!canReset} size="small" style={{ }}>{msg_reset}</Button>
                <Button type="primary" onClick={this.confirm} size="small" style={{ }}>{msg_confirm}</Button>
            </Row>
        </>);
    }


}
