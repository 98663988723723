import { Button, Card, Col, Form, Input, notification, Row, Space, } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import ZuFormDetail from "../Zerouno/ZuFormDetail";
import { FormInstance } from "antd/lib/form";
import { injectIntl } from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";
import { msg_operationError, msg_standardValidationError, msg_unexpectedError } from "../utils/constants";

class UserCambioPasswordComponent extends ZuFormDetail {
    readonly title = this.props.intl.formatMessage({ id: "utente.utente" });
    formRef = React.createRef<FormInstance>();
    recaptchaRef = React.createRef<any>();

    static defaultProps = {
        baseUrl: "api/Users",
    };

    state = {
        data: null,
        passwordChanged: false,
        confirmChanged: false,        
        captcha: false,
        loading: false,
    };

    componentDidMount() {
        // if (!this.isNew) {
        //     this.load(this.props.match.params.id).then((data: any) => {
        //         if (data != undefined) {
        //             //data.password = "      "; serverside
        //             //data.confirm = "      "; serverside
        //             this.setState({ data: data });
        //             this.formRef.current?.setFieldsValue(data);
        //         }
        //     });
        // }
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });
        let data = {
            ...values,
            RecaptchaToken: this.recaptchaRef?.current?.getValue()
        };

        // var oldPassword = this.state.data?.password;
        // if (oldPassword) {
        //     // forzo perche non posso verificare se la password è cambiata lato server
        //     data.passwordChanged = values.password !== oldPassword;
        // }  
        // this.submit(data).then((res) => {
        //     if (res?.ok) window.history.back();
        // });
        const response = await fetch("api/Account/ChangePassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...data
            })
        });
        const res = await response;

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            notification.success({ message: 'Password cambiata con successo' })
            this.setState({ loading: false });
            window.history.back();
        }
        else {
            //reset captcha
            this.recaptchaRef.current.reset();
            let json = await response?.json();
            if (typeof json.message === 'string' ||json.message instanceof String) {
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message}</>,
                    "duration": 60
                });
            }
            else{
                notification.error({
                    "message": msg_standardValidationError,
                    "description": <>{json.message.map((x:any)=> <p>{x.description}</p>)}</>,
                    "duration": 60
                });
            }

            this.setState({ loading: false });
        }
    };

    handlePasswordBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ passwordChanged: true });
    };
    handleConfirmBlur = (e: any) => {
        var value = e.target.value;
        if (value && value.trim() && value.trim().length > 0)
            this.setState({ confirmChanged: true });
    };
    onReCaptchaChange = (value: any) => {        
        this.setState({
            captcha: value,
        });
    };

    render() {
        const { data, passwordChanged, confirmChanged } = this.state;

        const msgPasswordNonCoincidono = this.props.intl.formatMessage({ id: "utente.msgPasswordNonCoincidono" });
        const msgPasswordNonValida = this.props.intl.formatMessage({ id: "utente.msgPasswordNonValida" });

        return (
            <>
                <Card>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.handleSubmit}
                    >
                        <Row gutter={16}>
                            <Col md={{ offset: 8, span: 8 }} sm={24}>
                                <Form.Item
                                    label={this.props.intl.formatMessage({ id: "utente.oldPassword" })}
                                    hasFeedback
                                    name="oldPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        visibilityToggle={passwordChanged}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={{ offset: 8, span: 8 }} sm={24}>
                                <Form.Item
                                    label={this.props.intl.formatMessage({ id: "utente.newPassword" })}
                                    hasFeedback
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 8,
                                            message:
                                                msgPasswordNonValida,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        visibilityToggle={passwordChanged}
                                        onBlur={this.handlePasswordBlur}
                                        autoComplete="new-password"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={{ offset: 8, span: 8 }} sm={24}>
                                <Form.Item
                                    label={this.props.intl.formatMessage({ id: "utente.confermaPassword" })}
                                    hasFeedback
                                    name="confirmPassword"
                                    dependencies={["newPassword"]}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                var password = getFieldValue(
                                                    "newPassword"
                                                );
                                                if (password !== value) {
                                                    return Promise.reject(
                                                        msgPasswordNonCoincidono
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        visibilityToggle={confirmChanged}
                                        onBlur={this.handleConfirmBlur}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col  md={{ offset: 8, span: 8 }} sm={24} style={{ paddingBottom: 16 }}>
                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey="6Ldb4eUeAAAAALyj4GvaEuu4xjF2Xj-6dXF3ak7W"
                                    onChange={this.onReCaptchaChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={{ offset: 8, span: 8 }} sm={24}>
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit" loading={this.state.loading}>{this.props.intl.formatMessage({ id: "global.conferma" })}</Button>
                                        <Button onClick={() => window.history.back()}>{this.props.intl.formatMessage({ id: "global.annulla" })}</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}

export default injectIntl(
    connect()(UserCambioPasswordComponent)
)
