import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as CataloghiStore from '../store/cataloghiStore';
import { FormInstance } from "antd/lib/form";
import { Card, Col, Row, Space, Typography, Image, Descriptions, Divider, Select, Form, InputNumber, Button, Menu, Dropdown, Modal, Input, message, Empty } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { TypePredicateKind } from 'typescript';
import { msg_requiredField } from '../utils/constants';
import ZuFormDetail from '../Zerouno/ZuFormDetail';
import SubMenu from 'antd/lib/menu/SubMenu';
import CataloghiDetail from './CataloghiDetail';

const { Title, Paragraph } = Typography;
const { Option } = Select;


class CataloghiComponent extends ZuFormDetail {
    formRef = React.createRef<FormInstance>();

    state = {
        cataloghi: [] as any,
        selectedKey: "",
        catalogo: [] as any,
        creaCatalogo: false,
        loadingBtn: false,
    }

    componentDidUpdate = (propsPrecedenti: any, statePrecedente: any) => {
        if (propsPrecedenti.cataloghi != this.props.cataloghi) {
            const cataloghi = this.props.cataloghi;
            let selectedKey = Object.keys(cataloghi).length > 0 ? cataloghi[0].id.toString() : "";            
            var catalogoArray = Object.values(cataloghi);

            if (statePrecedente.selectedKey !== ""){
                if ((catalogoArray || []).find((x: any) => x.id.toString() === statePrecedente.selectedKey)){
                    selectedKey = statePrecedente.selectedKey;
                }                
            }

            let catalogo = (catalogoArray || []).find((x: any) => x.id.toString() === selectedKey) ?? [];
            this.setState({ cataloghi: catalogoArray, selectedKey: selectedKey, catalogo: catalogo });
        }
    }

    componentDidMount = async () => {
        var { cataloghi } = this.props;
        var res = await fetch("/api/Cataloghi/GetCataloghi");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCataloghi([]);
                return;
            } else {
                cataloghi = json;
                // descrizioneLinea = json.descrizione;
                this.props.setCataloghi(cataloghi);
                return;
            }
        }

        if (this.props.cataloghi != null) {
            const cataloghi = this.props.cataloghi;
            let selectedKey = Object.keys(cataloghi).length > 0 ? cataloghi[0].id.toString() : "";
            var catalogoArray = Object.values(cataloghi);
            let catalogo = (catalogoArray || []).find((x: any) => x.id.toString() === selectedKey) ?? [];
            this.setState({ cataloghi: catalogoArray, selectedKey: selectedKey, catalogo: catalogo });
        }

    }

    handleMenu = (e: any) => {
        const cataloghi = this.state.cataloghi;
        let catalogo = (cataloghi || []).find((x: any) => x.id.toString() === e.key) ?? [];
        this.setState({ selectedKey: e.key, catalogo: catalogo })
    }

    aggiornaCataloghi = async () => {
        var res = await fetch("/api/Cataloghi/GetCataloghi");
        var json = res.ok && await res.json();
        if (json) {
            if (json && Object.keys(json).length === 0 && Object.getPrototypeOf(json) === Object.prototype) {
                //Risposta vuota no carrello
                this.props.setCataloghi([]);
                this.setState({ creaCatalogo: false, loadingBtn: false });
                return;
            } else {
                let cataloghi = json;
                // descrizioneLinea = json.descrizione;
                this.props.setCataloghi(cataloghi);
                this.setState({ creaCatalogo: false, loadingBtn: false });
                return;
            }
        }
    }


    creaCatalogo = async (values: any) => {
        this.setState({ loadingBtn: true });
        let model = { descrizione: values.nomeCatalogo };

        const response = await fetch("api/Cataloghi/CreateCatalogo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...model
            })
        });
        const res = await response;
        let data: any = {};

        if (res.ok) {
            // data = await (res.ok && res.json()) || {};
            setTimeout(this.aggiornaCataloghi, 1000);
        }
        else {
            message.error("Errore");
            //this.setState({ loading: false });
        }
    }

    render() {
        const { selectedKey, catalogo, cataloghi, creaCatalogo } = this.state;

        let menuItem = [] as any;
        (cataloghi || []).map((l: any) =>
            menuItem.push(<Menu.Item key={l.id}>{l.descrizione}</Menu.Item>)
        )
        return (
            <>
                <Card className='printCard'>
                    {cataloghi.length > 0 ?
                        <Row gutter={16}>
                            <Col md={5} className="noprint">
                                <Row>
                                    <Button type='link' onClick={() => this.setState({ creaCatalogo: true })}><PlusOutlined /> Crea nuovo catalogo</Button>
                                    <Divider style={{ marginTop: 10 }} />
                                    <Menu
                                        style={{ width: '100%', border: 'none' }}
                                        selectedKeys={[selectedKey]}
                                        mode="inline"
                                        onClick={this.handleMenu}
                                    >
                                        {menuItem}
                                    </Menu>
                                </Row>
                            </Col>
                            <Col md={17} offset={1}>
                                <CataloghiDetail catalogo={catalogo} />
                            </Col>
                        </Row>
                        :
                        <Empty description="Non sono presenti cataloghi">
                            <Button type='primary' onClick={() => this.setState({ creaCatalogo: true })}><PlusOutlined /> Crea il tuo catalogo</Button>
                        </Empty>
                    }
                </Card>

                {creaCatalogo &&
                    <Modal
                        visible={creaCatalogo}
                        title="Crea nuovo catalogo"
                        onOk={() => this.formRef.current?.submit()}
                        onCancel={() => this.setState({ creaCatalogo: false })}
                        maskClosable={false}
                        forceRender={true} // necessario !!!
                        width={800}
                        okButtonProps={{ loading: this.state.loadingBtn }}
                    >
                        <Form ref={this.formRef} layout="vertical" onFinish={this.creaCatalogo} >
                            <Row gutter={16}>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Nome catalogo" name="nomeCatalogo" rules={[{ required: true, message: msg_requiredField }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                }
            </>
        );
    };
};

export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.carrello, ...state.cataloghi }),
    { ...AccountStore.actionCreators, ...CataloghiStore.actionCreators }
)(CataloghiComponent);