import './Layout.less';
import logo from './logo.png';
import * as React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AccountStore from '../store/accountStore';
import * as LayoutStore from '../store/layoutStore';
import ZuAccountHelper from '../Zerouno/ZuAccountHelper';
import { ProfileOutlined, HomeOutlined, ShoppingCartOutlined, SettingOutlined, TagsOutlined,  TeamOutlined, UsergroupAddOutlined, SearchOutlined } from '@ant-design/icons';

import { Layout, Menu, Row, Button, Select, Space, Badge } from 'antd';
import { ruoli } from '../utils/constants';
import { injectIntl } from 'react-intl';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;


class LayoutComponent extends React.Component<any> {

    state = {
        collapsed: false,
        selectedKeys: ["0"],
    };

    toggleMenu = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    fillMenu = (x: any, i: number) => {
        // Verifica ruoli
        if ((x.roles.length > 0 && x.roles.filter((r: any) => this.props.user?.roles?.includes(r)).length === 0))
            return null;

        const key=`${i + 1}_${x.text}`;

        if (x.divider) return <Menu.Divider key={key} />;
        if (x.menu) return <SubMenu key={key} icon={x.icon} title={x.text}>{x.menu.map(this.fillMenu)}</SubMenu>
        
        return (
            <Menu.Item key={key}>
                <Link to={x.link || ''}>
                    {x.icon} <span>{x.text} </span>
                </Link>
            </Menu.Item>
        );
    }

    handleLanguageChange= (value:any)=>{

    };

    onClick = (values: any) => {
        this.setState({selectedKeys: [values.key]})
    }

    render() {
        const iconStyle = { fontSize: 16 }
        const { collapsed, selectedKeys } = this.state;

        const menu = [
            { link: "/", icon: <HomeOutlined />, text: this.props.intl.formatMessage({ id: "menu.home" }), roles: []},
            { divider: true, roles: [ruoli.Amministratore] },
            { icon: <SettingOutlined />, text: this.props.intl.formatMessage({ id: "menu.amministrazione" }), roles: [ruoli.Amministratore], menu: [
                { link: "/Users", icon: <TeamOutlined />, text: this.props.intl.formatMessage({ id: "menu.utenti" }), roles: [ruoli.Amministratore] },
            ]
            }
        ];

        const { changeLocale,defaultLang,languages,carrello } = this.props;        

        return (
            <BrowserRouter>
                <Layout className="fill-screen">
                    <Layout>
                     <Header className="layout-header noprint" >
                            <Row justify="space-between">

                                <Space>
                                    <div className="layout-logo">
                                        <Link to="/">
                                            <img className="layout-logo-image" alt="logo" src={logo} />
                                        </Link>
                                    </div>
                                    {this.props.user.isAuthenticated &&
                                        // <Menu mode="horizontal" selectedKeys={selectedKeys} style={{borderBottom: 0}} onClick={this.onClick}>
                                        //     <Menu.Item key="0" icon={<HomeOutlined />}>
                                        //         <Link to="/">
                                        //             Home
                                        //         </Link>
                                        //     </Menu.Item>
                                        //     <Menu.Item key="1" icon={<TagsOutlined />}>
                                        //         <Link to="/Cataloghi">
                                        //             Cataloghi &nbsp;&nbsp;
                                        //         </Link>
                                        //     </Menu.Item>
                                        <Space size="large">
                                            <Link to="/">
                                                <Button type='text'><HomeOutlined /> Home</Button>
                                            </Link>
                                            <Link to="/Catalogo">
                                                <Button type='text'><SearchOutlined /> Cerca prodotti</Button>
                                            </Link>
                                            <Link to="/IMieiCataloghi">
                                            <Button type='text'><TagsOutlined /> I miei cataloghi</Button>
                                            </Link>

                                            {this.props.user.roles.find((x: any) => x === ruoli.Amministratore) &&
                                                // <>
                                                // <Menu.Item key="2" icon={<UsergroupAddOutlined />}>
                                                //     <Link to="/Users">
                                                //         Gestione utenti &nbsp;&nbsp;
                                                //     </Link>
                                                // </Menu.Item>
                                                // <Menu.Item key="3" icon={<ProfileOutlined />}>
                                                //     <Link to="/Contenutipromozionali">
                                                //         Contenuti promozionali &nbsp;&nbsp;&nbsp;&nbsp;
                                                //     </Link>
                                                // </Menu.Item>
                                                // </>
                                                <>
                                                    <Link to="/Users">
                                                    <Button type='text'> <UsergroupAddOutlined /> Gestione utenti</Button>
                                                    </Link>
                                                    <Link to="/Contenutipromozionali">
                                                    <Button type='text'>  <ProfileOutlined />Contenuti promozionali</Button>
                                                    </Link>
                                                </>
                                            }
                                        </Space>
                                        // </Menu>
                                    }
                                </Space>

                                <Space>
                                    {this.props.user.isAuthenticated &&
                                        <Link to="/Carrello">
                                            <Badge count={Object.keys(carrello ?? {}).length} size="small" offset={[-5, 5]}>
                                                <Button onClick={()=>this.setState({selectedKeys: ['']})} type="ghost" style={{ border: 0, boxShadow: "0 0px 0" }} shape="circle" icon={<ShoppingCartOutlined /*style={{ fontSize: "1.25em" }} onClick={() => { window.location.href = '/carrello';}}*/ />} />
                                            </Badge>
                                        </Link>
                                    }
                                    <ZuAccountHelper checkIdentityOnMount={false} />
                                </Space>
                            </Row>

                        </Header>

                        <Content style={{
                            margin: '8px 16px',
                            padding: 0,
                            minHeight: 280,
                        }}>

                            {this.props.children}


                        </Content>
                        <Footer className="layout-footer noprint">Copyright &copy; {new Date().getFullYear()} Faro Italia - Zerogroup<br /></Footer>
                    </Layout>
                </Layout>



            </BrowserRouter>
        );

    };

};

export default connect(
    (state: ApplicationState) => { return { ...state.layout, ...state.account, ...state.carrello } },
    { ...AccountStore.actionCreators, ...LayoutStore.actionCreators }
)(injectIntl(LayoutComponent));